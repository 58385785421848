import React, { Component } from "react";
import "./style.scss";
import { Modal } from "../../components";

class CourseVideo extends Component {
  render() {
    const { course, onClose } = this.props;
    const src_video = "https://www.youtube.com/embed/" + course.youtube_id;
    return (
      <Modal handleClose={onClose}>
        <h2>{course.course_code}</h2>
        <span tabIndex='0'>
          <iframe tabIndex='0' width='650' height='366' src={src_video} title='youtube'></iframe>
        </span>
      </Modal>
    );
  };
};

export default CourseVideo;