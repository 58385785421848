import React, { Component, Fragment, createRef } from 'react';
import PropTypes from "prop-types";
import './style.scss';

import { makeClassName } from "../../../utils";

class SuccessBanner extends Component {
  constructor(props) {
    super(props);
    this.focusRef = createRef();
  }

  focusOnSuccess = () => {
    if (this.props.shouldFocusOnSuccess && this.props.message) {
      this.focusRef.current.focus();
    }
  };

  componentDidUpdate() {
    this.focusOnSuccess();
  }

  render() {
    let { message, className, id } = this.props;

    return (
      <Fragment>
        { message &&
            <div
              id={id}
              className={makeClassName("ucop-success-banner", className)}
              ref={this.focusRef}
              tabIndex={message ? "-1" : ""}
              role="alert"
            >
              {message}
            </div>
        }
      </Fragment>
    );
  }
}

SuccessBanner.defaultProps = {
  shouldFocusOnSuccess: true
};

SuccessBanner.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.node,
  shouldFocusOnSuccess: PropTypes.bool
};

export default SuccessBanner;
