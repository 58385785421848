import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";

import { composeWithDevTools } from "redux-devtools-extension";
import { isProd } from "./utils";

const composeFunction = isProd()
  ? compose
  : composeWithDevTools({ trace: true, traceLimit: 25 });

export default createStore(reducers, composeFunction(applyMiddleware(thunk)));
