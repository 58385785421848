import React, { Component, Fragment, createRef } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import { Pagination } from "../";
import Tooltip from "../tooltip/index";
import { makeClassName } from "../../../utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp, faSort } from '@fortawesome/free-solid-svg-icons'
import MediaQuery from 'react-responsive'

import {
  CATALOG_COURSE_DETAILS_ROUTE,
  SEARCH_COURSE_OFFERING_DETAILS_ROUTE
} from "../../../constants/routes";

class Table extends Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
    // we want to extract our filter/sort functions from our column schema for use later
    // we also need to set some initial state for the filters/sort functions
    const filters = {};
    const sortFunctions = {};
    const itemsPerPageOptions = this.props.itemsPerPageOptions ||
      [
        {'value' : 10, 'name': '10', 'default': true},
        {'value' : 20, 'name': '20', 'default': false},
        {'value' : 30, 'name': '30', 'default': false}
      ];
    let activeSortFunction;

    this.props.columns.forEach(col => {
      // extract filters and set initial state
      if (typeof col.filter === "function") {
        filters[col.name] = {
          method: col.filter,
          active: Boolean(col.initialFilterValue),
          value: col.initialFilterValue || "",
        };
      }

      // extract sort functions and set initial state
      if (typeof col.sort === "function") {
        sortFunctions[col.name] = {
          method: col.sort,
          descending: Boolean(col.descending)
        };
        if (col.defaultSort) {
          activeSortFunction = col.name;
        }
      }
    });

    const itemsPerPage = Math.max(parseInt(this.props.itemsPerPage), 10);
    const newPageCount = Math.ceil(this.props.data.length / itemsPerPage);
    const currentPage = Math.min(Math.max(parseInt(this.props.page), 1), newPageCount) || 1;

    this.state = {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
      itemsPerPageOptions: itemsPerPageOptions,
      filters,
      sortFunctions,
      activeSortFunction,
      searchParams: this.props.searchParams,
      hideViewDetailsButton: this.props.hideViewDetailsButton || false
    };

    this.focusRef = createRef();
  }

  selectSortFunction = name => {
    this.setState({ activeSortFunction: name });
  };

  setSortDirection = (name, descending) => {
    const { sortFunctions } = this.state;
    const currentSortFunction = sortFunctions[name];
    sortFunctions[name] = {
      ...currentSortFunction,
      descending
    };

    this.setState({ sortFunctions });
  };

  setFilterState = (name, active) => {
    const filters = this.state.filters;
    const currentFilter = filters[name];
    filters[name] = {
      ...currentFilter,
      value: active ? currentFilter.value : "",
      active
    };

    this.setState({ filters });
  };

  setFilterValue = (name, value) => {
    const filters = this.state.filters;
    const currentFilter = filters[name];

    filters[name] = {
      ...currentFilter,
      value
    };


    this.setState({
      filters
    });
  };

  setItemsPerPage = (value) => {
    this.setState({
      itemsPerPage: parseInt(value, 10)
    });

    if (this.props.setPagination) {
      this.props.setPagination(this.state.currentPage, value);
    }

  }

  onPageChange = (page, total) => {
    this.setState({
      currentPage: page
    });

    if (this.props.setPagination) {
      this.props.setPagination(page, this.state.itemsPerPage);
    }

    this.selectRef.current.scrollIntoView();
    //this.selectRef.current.focus();
  };

  skipAction = () => {
    if (this.focusRef.current) {
      this.focusRef.current.focus();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { currentPage, itemsPerPage } = this.state;

    const newPageCount = Math.ceil(this.filteredData.length / itemsPerPage);
    // if we are left on a non-existant page after filtering then we can
    // move to the last page
    if (Math.max(1, currentPage) > Math.max(1, newPageCount)) {
      this.setState({currentPage: Math.max(1, newPageCount)});
    }
  }

  getFilteredData = data => {
    const { filters } = this.state;

    // iterate over each filter's value: {active, value, filter}
    const filteredData = Object.values(filters).reduce((filtered, filter) => {
      // apply active filters to our data
      if (filter.active && filter.value) {
        return filtered.filter(item => filter.method(item, filter.value));
      }
      // we just pass the data along unfiltered if the filter isn't active
      return filtered;
    }, data);

    return filteredData;
  };

  getSortedData = data => {
    const { activeSortFunction, sortFunctions } = this.state;
    const currentSortFunction = sortFunctions[activeSortFunction];
    if (
      !currentSortFunction ||
      typeof currentSortFunction.method !== "function"
    ) {
      return data;
    }

    const sorted = data.sort(currentSortFunction.method);

    return currentSortFunction.descending ? sorted.reverse() : sorted;
  };

  getPaginatedData = data => {
    const { currentPage, itemsPerPage } = this.state;

    if (typeof itemsPerPage === "undefined") {
      return {
        totalPages: 1,
        tableData: data
      };
    }

    const totalPages = Math.ceil(data.length / itemsPerPage);
    const sliceStart = (currentPage - 1) * itemsPerPage;
    const page = data.slice(sliceStart, sliceStart + itemsPerPage);
    const firstItemNumber = sliceStart + 1;
    const itemCount = data.length;
    const lastItemNumber = Math.min(sliceStart + itemsPerPage, itemCount);

    const statusString = (totalPages > 1) ?
      `Displaying ${data.length ? firstItemNumber : 0} - ${lastItemNumber} out of ${itemCount} results.` :
      `Displaying ${data.length ? firstItemNumber : 0} - ${itemCount} out of ${itemCount} results.`;

    return {
      totalPages,
      statusString,
      tableData: page
    };
  };

  get filteredData() {
    return this.getFilteredData(this.props.data);
  }

  get sortedData() {
    return this.getSortedData(this.filteredData);
  }

  get paginatedData() {
    return this.getPaginatedData(this.sortedData);
  }

  scrollToTop() {
    window.scrollTo(0, 0)
  }
  render() {
    const { id, caption, columns, className, keyFunction, searchParams } = this.props;
    const {
      tableData,
      totalPages,
      statusString
    } = this.paginatedData;
    const {
      activeSortFunction,
      itemsPerPageOptions,
      hideViewDetailsButton
    } = this.state;
    const needsPagination = totalPages > 1;

    let queryParams  = "&";
    for (const [key, value] of Object.entries(searchParams)) {
      if (Array.isArray(value)) {
        queryParams += key + '=' + value.join(',') + '&';
      } else {
        queryParams += key + '=' + value + '&';
      }
    }

    return (
      <div id={id} className={makeClassName("ucop-table", className)}>
        {needsPagination && (
          <div aria-label="skiplink" role="navigation">
            <button className="skip-link" onClick={this.skipAction}>
              Skip to pagination controls
            </button>
          </div>
        )}
        <div className="table-summary">
          {statusString}
          <span id="set-page-size">
          <label>Results Per Page</label>
          <select name="page-size" id="page-size"
            onChange={e =>
              this.setItemsPerPage(e.target.value)
            }
            value={this.state.itemsPerPage}
            ref={this.selectRef}
          >
            {itemsPerPageOptions.map((option, idx) => {
              return (
               <option key={idx} value={option.value || this.props.data.length}>{option.name}</option>
              )
            })}
          </select>
          </span>
        </div>
        <table>
          <caption className="sr_hide">{caption}</caption>
          <tbody>
            {/* COLUMN HEADERS */}
            <tr>
              {/* we iterate over each column and either render a bunch of filter stuff or */}
              {/* just the column's display name */}
              {columns.map((col, idx) =>
                (col.filter || col.sort) ? (
                  <th
                    className="filterable-header"
                    key={idx}
                    scope="col"
                    width={col.width}
                  >
                    {
                      col.filter ? (
                        <span
                        className="header-filter"
                        aria-label={`Filter by: ${col.displayName}`}
                        onClick={() => this.setFilterState(col.name, true)}
                      >
                        {col.displayName}
                        <Tooltip>
                          Click header to {`filter by:${col.displayName}`}
                        </Tooltip>
                      </span>
                      ) : col.displayName
                    }

                    {col.filter && this.state.filters[col.name].active && (
                      <Fragment>
                        <input
                          className="filter-field"
                          onChange={e =>
                            this.setFilterValue(col.name, e.target.value)
                          }
                          value={this.state.filters[col.name].value}
                        />
                        <button
                          className="btn-close-tooltip"
                          onClick={() => this.setFilterState(col.name, false)}
                        >
                          X
                        </button>
                      </Fragment>
                    )}

                    {
                      col.sort && (
                        <Fragment>
                          <span
                            onClick={() => {
                              this.selectSortFunction(col.name);
                              this.setSortDirection(col.name, !this.state.sortFunctions[col.name].descending);
                            }}
                          >
                            {activeSortFunction !== col.name && <FontAwesomeIcon icon={faSort} color="gray"/>}
                            {activeSortFunction === col.name && this.state.sortFunctions[col.name].descending && <FontAwesomeIcon icon={faSortDown} color="gray"/>}
                            {activeSortFunction === col.name && !this.state.sortFunctions[col.name].descending && <FontAwesomeIcon icon={faSortUp} color="gray"/>}
                          </span>
                        </Fragment>
                      )
                    }
                  </th>
                ) : (
                  <th key={idx} scope="col" width={col.width}>
                    {col.displayName}
                  </th>
                )
              )}
            </tr>

            {/* TABLE ROWS */}
            {tableData.length ? (
              tableData.map((item, idx) => (
                <Fragment key={idx}>
                <tr key={typeof keyFunction === 'function' ? keyFunction(item, idx) : idx}>
                  {/* we iterate over each column and look up the appropriate data */}
                  {/* if the column has a displayTransformer function we call it with the rest of an item's data */}
                  {/* otherwise we just display the data that matches the column name */}
                  {/* if we don't have anything for that column we render an empty string */}
                  {columns.map((col, idx) => (
                    <td key={idx} width={col.width} data-label={col.displayName}>
                      {(typeof col.displayTransformer === "function"
                        ? col.displayTransformer(item, searchParams)
                        : item[col.name]) || ""}
                    </td>
                  ))}
                </tr>
                <MediaQuery maxWidth={768}>
                  {!hideViewDetailsButton &&
                  <tr className="mobile_row view_details"><td colSpan={columns.length} className="mobile_cell">
                    <a className="view_details_a" href={item['offering_id']  ?
                      SEARCH_COURSE_OFFERING_DETAILS_ROUTE + '?offering_id=' + item['offering_id'] + '&home_campus_id=' + item['home_campus_id'] + CATALOG_COURSE_DETAILS_ROUTE + '?id=' + item['course_id'] + queryParams :
                      CATALOG_COURSE_DETAILS_ROUTE + '?id=' + item['course_id'] + queryParams
                    }>
                      <span className="mobile_text">View details</span></a></td></tr>
                  }
                  <tr className="mobile_row back_to_top"><td colSpan={columns.length}><a href="#main-content" className="back_to_top_a"><span className="mobile_text back_to_top" onClick={this.scrollToTop}>Back to top</span></a></td></tr>
                </MediaQuery>
                </Fragment>
              ))
            ) : (
              <tr>
                <td className="no-content-table" colSpan={columns.length}>No content in table.</td>
              </tr>
            )}
          </tbody>
        </table>
        {needsPagination && (
          <Pagination
            focusRef={this.focusRef}
            totalPages={totalPages}
            onChange={this.onPageChange}
            page={this.state.currentPage}
          />
        )}
      </div>
    );
  }
}

Table.propTypes = {
  caption: PropTypes.node,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  itemsPerPage: PropTypes.number,
  itemsPerPageOptions: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  page: PropTypes.number
};

export default Table;
