import React, { Component, Fragment, useLocation } from 'react';
import { useSearchParams } from "react-router-dom";
import { useDispatch, connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import './style.scss';
import dompurify from 'dompurify';
import parse from 'html-react-parser'

import {
  PageContainer,
  PageContent,
} from '../../../components';

import {
  Spinner,
  Button,
} from '../../../components/widgets';

import {
  fetchAndLoadCourseOfferingDetails,
  resetAllMessages,
  setErrors,
  setShowNotifyMe,
} from "../../../actions"

import {
  SEARCH_RESULTS_ROUTE,
} from "../../../constants/routes";

import KeyInformation from './key-information';
import EnrollmentInformation from './enrollment-information';
import FacultyInformation from './faculty-information';
import AdditionalInformation from './additional-information';
import NotifyMeModal from './notify-me-modal';

const mapStateToProps = (state, props) => ({
  isLoading: state.courseOfferingDetails.isLoading,
  isLoaded: state.courseOfferingDetails.loaded,
  course_offering_details: state.courseOfferingDetails.course_offering_details,
  errors: state.messages.errors,
  show_notify_me: state.search.show_notify_me,
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchAndLoadCourseOfferingDetails: (offering_id, home_campus_id) => dispatch(fetchAndLoadCourseOfferingDetails(offering_id, home_campus_id)),
    resetAllMessages: () => dispatch(resetAllMessages),
    setErrors: () => dispatch(setErrors),
    onCloseNotifyMe: () => dispatch(setShowNotifyMe(false)),
    onShowNotifyMe: () => dispatch(setShowNotifyMe(true)),
  };
};

const CourseVideo= props => {
  return (
    (props.display_video && props.display_video.length > 0) &&
      <div className='course_video'>
        <div className='video_wrapper'>
          {parse(dompurify.sanitize(props.display_video, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }))}
        </div>
      </div>
  );
};

class CourseOfferingDetailsPage extends Component {

  componentDidMount() {
    this.props.fetchAndLoadCourseOfferingDetails(this.props.searchParams.get('offering_id'), this.props.searchParams.get('home_campus_id'));
  }

  getSearchURL = () => {
    const { searchParams } = this.props;
    let url = SEARCH_RESULTS_ROUTE;
    url += '?' +
          '&home_campus=' + searchParams.get('home_campus') +
          '&term_year=' + searchParams.get('term_year') +
          '&subject_area=' + searchParams.get('subject_area') +
          '&host_campus=' + searchParams.get('host_campus') +
          '&instructor_name=' + searchParams.get('instructor_name') +
          '&with_preview=' + searchParams.get('with_preview') +
          '&approvals=' + searchParams.get('approvals') +
          '&dow=' + searchParams.get('dow') +
          '&start_time=' + searchParams.get('start_time') +
          '&end_time=' + searchParams.get('end_time') +
          '&page_number=' + searchParams.get('page_number') +
          '&items_per_page=' + searchParams.get('items_per_page')
    return url;
  }

  onBackButton = (e) => {
    window.location.href = this.getSearchURL();
  }

  render() {
    const {
      isLoaded,
      isLoading,
      course_offering_details,
      show_notify_me,
      onCloseNotifyMe,
      onShowNotifyMe,
    } = this.props;
    const course = course_offering_details.course;

    return (
      <PageContainer className="search-results">
        <Helmet>
          {isLoaded &&
            <title>{course.course_code}: {course.course_title} at {course_offering_details.host_campus.campus_name} | UC Online</title>
          }
        </Helmet>
        <PageContent>
          {isLoading && <Spinner />}
          {isLoaded &&
            <Fragment>
              <Button className="back-btn"  onClick={this.onBackButton}>&nbsp;Back to Search Results</Button>
              <div id='course_view'>
                <div id='course_info'>
                  <h2>{course.course_title}, {course.course_code}</h2>
                  <div id='course_description'>
                    <p className='long_description mobile_small_text'>{parse(dompurify.sanitize(course.long_description))}</p>
                    <CourseVideo display_video={course_offering_details.display_video}/>
                  </div>
                  <KeyInformation course_offering_details={course_offering_details} onShowNotifyMe={onShowNotifyMe}/>
                </div>
                <EnrollmentInformation course_offering_details={course_offering_details}/>
                <AdditionalInformation course={course_offering_details.course} offering={course_offering_details.offering}></AdditionalInformation>
                <FacultyInformation course_creators={course_offering_details.course_creators} course_instructors={course_offering_details.course_instructors}></FacultyInformation>
              </div>
              { show_notify_me && (<NotifyMeModal onCloseNotifyMe={onCloseNotifyMe} course={course} term={course_offering_details.term} returnURL={this.getSearchURL()}/>) }
            </Fragment>
          }
        </PageContent>
      </PageContainer>
    );
  }
}


const CourseOfferingDetails = (props) => {
  const [searchParams] = useSearchParams();
  return <CourseOfferingDetailsPage searchParams={searchParams} {...props}/>;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseOfferingDetails);