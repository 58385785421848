import React, { Component, Fragment, Link } from "react";
import "./style.scss";
import { connect } from 'react-redux';
import Moment from 'react-moment';

import {
  setShowAllAvailableSections,
} from "../../../actions"

const mapStateToProps = (state, props) => ({
  showAllSections: state.courseOfferingDetails.showAllSections,
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    setShowAllAvailableSections: () => dispatch(setShowAllAvailableSections(true)),
  };
};

class KeyInformation extends Component {

  onShowNotifyMe = () => {
    this.props.onShowNotifyMe();
  }

  render() {
    const {
      course_offering_details,
      setShowAllAvailableSections,
    } = this.props;
    const course = course_offering_details.course;
    const offering = course_offering_details.offering;
    const hasMultipleSections = course_offering_details.sections.length > 1 ? true : false;
    const section = course_offering_details.sections[0];
    const home_campus = course_offering_details.home_campus;
    const same_campus = home_campus.campus_id === course.host_campus_id;
    const openNewWindow = (url) => {
      window.open(url, 'mywin', 'left=20,top=20,width=1000,height=500,toolbar=1,resizable=0')
    }

    const scrollToAllSections = () => {
      setShowAllAvailableSections();
      document.getElementById('section_meeting_times').scrollIntoView({
        behavior: 'smooth'
      });
    }
    return <Fragment>
      <div id='key_information'>
        <ul className='social_links_list'>
          <li className='print'>
            <button onClick={window.print}><img src={process.env.REACT_APP_CDN_URL + "/images/icons/search/print.png"} alt="Print" /></button>
          </li>
          <li className='fb-m'>
            <button onClick={() => openNewWindow("https://www.facebook.com/sharer/sharer.php?u=" + process.env.REACT_APP_PUBLIC_URL + "/view?offering_id=" + offering.offering_id + "&home_campus_id=" + home_campus.campus_id)}><img src="https://uconline.edu/sites/default/files/facebook.png" alt="Share the course on Facebook" /></button>
          </li>
          <li className='tw-m'>
            <button onClick={() => openNewWindow("http://twitter.com/share?text=" + process.env.REACT_APP_PUBLIC_URL + "/view?offering_id=" + offering.offering_id + "&home_campus_id=" + home_campus.campus_id)}><img src="https://uconline.edu/sites/default/files/twitter.png" alt="Share the course on Twitter" /></button>
          </li>
          <li className='li-m'>
            <button onClick={() => openNewWindow("https://www.linkedin.com/cws/share?url=" + process.env.REACT_APP_PUBLIC_URL + "/view?offering_id=" + offering.offering_id + "&home_campus_id=" + home_campus.campus_id)}><img src="https://uconline.edu/sites/default/files/linkedin.png" alt="Share the course on Linkedin" /></button>
          </li>
          <li>
            <span className="share_text mobile_small_text">Share</span>
          </li>
        </ul>
        <div className="academic_calendar_container mobile_small_text"><a className="academic_calendar pdf_icon" href={process.env.REACT_APP_CDN_URL + "/docs/academic-calendar.pdf"} target="_blank">Academic Calendar<span className="offscreen">(Opens a PDF in a new tab)</span></a></div>
        <div className="info_container">
          <p className="credit_info mobile_small_text">
            <strong>{course_offering_details.term.season + " " + course_offering_details.term.type + " " + course_offering_details.term.year}</strong><br/>
            <strong>Instruction start date:</strong> <Moment parse='YYYY-MM-DD hh:mm:ss' format='MMMM DD, YYYY'>{course_offering_details.campus_event.instruction_start_date}</Moment><br/>
            <strong>Instruction end date:</strong> <Moment parse='YYYY-MM-DD hh:mm:ss' format='MMMM DD, YYYY'>{course_offering_details.campus_event.instruction_end_date}</Moment><br/>
            <strong>Credit:</strong> {course_offering_details.unit_equivalency.quarter_equivalency_hours + " quarter units / " + course_offering_details.unit_equivalency.semester_equivalency_hours + " semester units credit"} <br/>
            {course_offering_details.host_campus.campus_name}, {course_offering_details.course.course_department} <br/>
            {course_offering_details.course.course_fee ?
              <Fragment>
                <strong>Course Fees:</strong> {course_offering_details.course.course_fee}
              </Fragment>
              : null
            }
          </p>
        </div>
        <div className="info_buttons">
          <a className="course_info_button mobile_small_text" cc-data={course.course_code} href={offering.syllabus} target="_blank" rel="noopener noreferrer">Syllabus<span className="offscreen">(Opens a PDF in a new tab)</span></a>
          {course_offering_details.open_for_enrollment && hasMultipleSections && !same_campus &&
            <a className="course_info_button notify_button mobile_small_text" id="show_all_sections" href="#section_anchor" onClick={() => scrollToAllSections()}>Enroll</a>
          }

          {course_offering_details.open_for_enrollment && !hasMultipleSections && !same_campus &&
            <a className="course_info_button notify_button" cn-data={course.course_code} href={section.enroll_url + "&campus=" + home_campus.campus_alias} target="_blank" rel="noopener noreferrer">Enroll<span className="offscreen">(Opens in a new tab)</span></a>
          }

          {course_offering_details.open_for_enrollment && same_campus &&
            <div className="course_info_message notify_button" id="register_at_your_campus">Register at your campus</div>
          }

          {!course_offering_details.open_for_enrollment && course_offering_details.is_futuring_offering &&
            <a className="course_info_button notify_button" id="notify_me" href="#section_anchor" onClick={this.onShowNotifyMe}>Notify me when available</a>
          }

          {!course_offering_details.open_for_enrollment && !course_offering_details.is_futuring_offering &&
            <div className="course_info_message notify_button" id="enrollment_closed">Enrollment closed</div>
          }
        </div>
      </div>
    </Fragment>
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KeyInformation);