import React, { Fragment } from "react";
import "./style.scss";
import dompurify from 'dompurify';
import parse from 'html-react-parser'


const FacultyInformation = props => {
    const { course_creators, course_instructors } = props;

    return <Fragment>
      <div id='faculty_info'>
        <div className='course_creators'>
          <h4>COURSE CREATORS</h4>
          {course_creators.map((creator, index) => (
            <Fragment key={creator.person_id}>
              <div className='course_creator_info'>
                <div className='faculty_photo'>
                  <img className="ie_image_cover" alt="" src={creator.person.photo}/>
                </div>
                <div className='ie_image_cover_neighbor'>
                  <h4>{creator.person.first_name} {creator.person.last_name}</h4>
                  <div className="course_creator_bio mobile_small_text">
                    <span className="visible_print">
                      <span>
                        <span>{parse(dompurify.sanitize(creator.person.bio, {ALLOWED_TAGS: []}))}</span>
                      </span>
                    </span>
                    <span className="visible_screen mobile_small_text" id="no-style">
                      <span>
                        <span>{parse(dompurify.sanitize(creator.person.bio, {ALLOWED_TAGS: []}))}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </Fragment>
            ))
          }

        </div>
        <div className="course_instructors mobile-small-text">
				<strong>Term Instructor: </strong>

          {course_instructors.map((instructor, index) => (
            <Fragment key={instructor.person_id}>
              <div className="course_instructor_info">
                <p className="mobile_small_text">
                  <strong>{instructor.person.first_name} {instructor.person.last_name}</strong>
                  <a href={"mailto:" + instructor.person.email}>{instructor.person.email}</a>
                </p>
              </div>
            </Fragment>
            ))
          }
        </div>
      </div>
    </Fragment>
};

export default FacultyInformation;