import {
  SEARCH_ERROR,
  makeGenericErrorMessage
} from '../constants/errors';

const FETCH_SEARCH_FILTERS_URL = process.env.REACT_APP_API_URL + "searchFilters";
const FETCH_SEARCH_INSTRUCTORS_URL = process.env.REACT_APP_API_URL + "searchInstructors";
const FETCH_SEARCH_COURSES_URL = process.env.REACT_APP_API_URL + "search?";
const FETCH_COURSES_OFFERING_DETAILS_URL = process.env.REACT_APP_API_URL + "search/view";
const FETCH_SEARCH_NOTIFY_ME_URL = process.env.REACT_APP_API_URL + "search/notifyMe";

const FETCH_CATALOG_COURSES_URL = process.env.REACT_APP_API_URL + "catalog?";
const FETCH_CATALOG_COURSE_DETAILS_URL = process.env.REACT_APP_API_URL + "catalog/view";

export const fetchSearchFilters = () => {
  const request = new Request(FETCH_SEARCH_FILTERS_URL);
  return fetch(request)
    .then(res => {
      // if we have a bad error code and no body content then bail out here
      if (!(res.ok || res.body)) {
        return Promise.reject(res);
      }

      return res.json();
    });
}

export const fetchSearchInstructors = () => {
  const request = new Request(FETCH_SEARCH_INSTRUCTORS_URL);
  return fetch(request)
    .then(res => {
      // if we have a bad error code and no body content then bail out here
      if (!(res.ok || res.body)) {
        return Promise.reject(res);
      }

      return res.json();
    });
}

export const fetchSearchResults = (search_params) => {
  let url = FETCH_SEARCH_COURSES_URL;
  for (const [key, value] of Object.entries(search_params)) {
    if (Array.isArray(value)) {
      url += key + '=' + value.join(',') + '&';
    } else {
      url += key + '=' + value + '&';
    }

  }
  const request = new Request(url);
  return fetch(request)
    .then(res => {
      // if we have a bad error code and no body content then bail out here
      if (!(res.ok)) {
        return Promise.reject(res);
      }

      return res.json();
    });
}

export const fetchCatalogResults = (search_params) => {
  let url = FETCH_CATALOG_COURSES_URL;
  for (const [key, value] of Object.entries(search_params)) {
    if (Array.isArray(value)) {
      url += key + '=' + value.join(',') + '&';
    } else {
      url += key + '=' + value + '&';
    }

  }
  const request = new Request(url);
  return fetch(request)
    .then(res => {
      // if we have a bad error code and no body content then bail out here
      if (!(res.ok || res.body)) {
        return Promise.reject(res);
      }

      return res.json();
    });
}

export const fetchCourseOfferingDetails= (offering_id, home_campus_id) => {
  const request = new Request(FETCH_COURSES_OFFERING_DETAILS_URL + "/" + offering_id + "?home_campus_id=" + home_campus_id);
  return fetch(request)
    .then(res => {
      // if we have a bad error code and no body content then bail out here
      if (!(res.ok || res.body)) {
        return Promise.reject(res);
      }

      return res.json();
    });
}

export const fetchCatalogCourseDetails= (course_id) => {
  const request = new Request(FETCH_CATALOG_COURSE_DETAILS_URL + "/" + course_id);
  return fetch(request)
    .then(res => {
      // if we have a bad error code and no body content then bail out here
      if (!(res.ok || res.body)) {
        return Promise.reject(res);
      }

      return res.json();
    });
}

export const notifyMe= (data) => {
  const request = new Request(FETCH_SEARCH_NOTIFY_ME_URL);
  return fetch(request, {method: 'POST', body: JSON.stringify(data)})
    .then(res => {
      if (!(res.ok || res.body)) {
        return Promise.reject(res);
      }

      return res.json();
    });
}