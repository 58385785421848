import React, { Component } from 'react';
import './style.scss';

import { makeClassName } from '../../../utils';

const parseOption = option => {
  const data = {};
  if (typeof option === 'object') {
    data.name = option.name;
    data.value = option.value
  } else {
    data.name = option;
  }

  if (typeof data.value === 'undefined') {
    data.value = data.name.toLowerCase();
  }


  return data;
};

class Select extends Component {
  state = {
    value: this.props.initialValue
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({
        value: this.props.initialValue
      })
    }
  }

  onChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event);
    }

    this.setState({
      value: event.target.value
    });
  }

  render() {
    const { focusRef, name, id, className, iconClassName, label, disabled, required, options, prompt, errorMessage } = this.props;
    return (
      <div className={makeClassName('ucop-div-select', className)}>
      <label className='ucop-select'>
        <div className='label-text'>{label}</div>
        <select
          ref={focusRef}
          name={name}
          id={id}
          value={this.state.value}
          onChange={this.onChange}
          disabled={disabled}
          required={required}
        >
          {prompt && <option value="">{prompt}</option>}
          {
            options.map((option, idx) => {
              const data = parseOption(option);
              return (
                <option key={idx} value={data.value}>{data.name}</option>
              );
            })
          }
        </select>
      </label>
      {errorMessage ? <span role="alert" className="input-field-error-container">{errorMessage}</span> : null}
      <div className={makeClassName('ucop-div-icon', iconClassName)}>
        <svg tabIndex="-1" width="12" height="4" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polyline className="cce-search-form-control-icon" points="0,0 6,3 12,0" fill="none" stroke="black"></polyline>
        </svg>
      </div>
      </div>
    );
  }


}

export default Select;
