import {
  LOAD_SUMMER_COURSES
} from '../constants/action-types';

import {
  fetchJsonSummerCourses
} from '../api'

import {
  setErrors
} from '.';

export const fetchSummerCourses = () => (dispatch, getState) => {
  fetchJsonSummerCourses().then(summerCourses => {
    dispatch(loadSummerCourses(summerCourses));
  }).catch(errors => {
    dispatch(setErrors({'INTERNAL_ERROR' : errors}));
  }).finally(() => {
  });
}

const loadSummerCourses = summer=> ({
  type: LOAD_SUMMER_COURSES,
  summer
});