import React, { Component, Fragment } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, connect } from 'react-redux';
import dompurify from 'dompurify';
import parse from 'html-react-parser'
import { makeClassName } from '../../utils';

import {
  SEARCH_COURSE_OFFERING_DETAILS_ROUTE,
  SEARCH_RESULTS_ROUTE,
  SEARCH_NONUC_ROUTE,
} from "../../constants/routes";

import {
  PageContainer,
  PageContent,
} from '../../components';

import {
  Select,
  Button,
  Table,
  Spinner,
  Form,
  ErrorList
} from '../../components/widgets';

import {
  fetchAndLoadSearchFilters,
  fetchAndLoadSearchCourses,
  fetchAndLoadSearchInstructors,
  resetAllMessages,
  setErrors,
  setSelectedCourse,
  setShowAdvancedSearch,
  setSearchParamsValues,
  resetSearchData,
} from "../../actions"

import {
  getCampusFilterList,
  getTermFilterList,
  getSubjectAreaFilterList,
  getHoursFilterList
} from "../../selectors";

import CourseVideo from './course-video-modal';
import AdvancedSearch from './advanced-search-modal';

import { INTERNAL_ERROR } from "../../constants/errors"

import './style.scss';

const mapStateToProps = (state, props) => ({
  isLoading: state.filters.isLoading,
  campuses: getCampusFilterList(state),
  terms: getTermFilterList(state),
  subjects: getSubjectAreaFilterList(state),
  hours: getHoursFilterList(state),
  search_results: state.search.search_results,
  search_params: state.search.search_params,
  isSearching: state.search.isSearching,
  isLoaded: state.search.isLoaded,
  isNonUCStudent: state.search.isNonUCStudent,
  isSearchResults: state.search.isSearchResults,
  errors: state.messages.errors,
  selected_course: state.search.selected_course,
  show_advanced_search: state.search.show_advanced_search,
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSearch: search_params => dispatch(fetchAndLoadSearchCourses({ ...search_params })),
    resetAllMessages: () => dispatch(resetAllMessages),
    setErrors: () => dispatch(setErrors),
    fetchAndLoadSearchFilters: () => dispatch(fetchAndLoadSearchFilters()),
    onClose: () => dispatch(setSelectedCourse(null)),
    onCloseAdvancedSearch: () => dispatch(setShowAdvancedSearch(false)),
    onShowAdvancedSearch: () => dispatch(setShowAdvancedSearch(true)),
    setSearchParamsValues: search_params => dispatch(setSearchParamsValues(search_params)),
    resetSearchData: () => dispatch(resetSearchData()),
    fetchInstructors: () => dispatch(fetchAndLoadSearchInstructors()),
  };
};

const CourseTitleLink = props => {
  const {course, searchParams} = props;
  const dispatch = useDispatch();

  const onClickVideo = (selected_course) => {
    dispatch(setSelectedCourse(selected_course));
  }

  return (
    <Fragment>
      <a href={
        SEARCH_COURSE_OFFERING_DETAILS_ROUTE +
        `?offering_id=` + course.offering_id +
        '&home_campus_id=' + course.home_campus_id +
        '&home_campus=' + searchParams.home_campus +
        '&term_year=' + searchParams.term_year +
        '&subject_area=' + searchParams.subject_area +
        '&host_campus=' + searchParams.host_campus +
        '&instructor_name=' + searchParams.instructor_name +
        '&with_preview=' + searchParams.with_preview +
        '&approvals=' + searchParams.approvals +
        '&dow=' + searchParams.dow +
        '&start_time=' + searchParams.start_time +
        '&end_time=' + searchParams.end_time +
        '&page_number=' + searchParams.page_number +
        '&items_per_page=' + searchParams.items_per_page
      }>{course.course_title}</a>
      { course.course_video_url ?
        <div>
        <span onClick={() => onClickVideo(course)} className="course_video_icon" tabIndex="0">
          <img src={process.env.REACT_APP_CDN_URL + '/images/fa-film.png'} alt="Video"/>&nbsp;&nbsp;Video
        </span>
        </div> : ''
      }

    </Fragment>
  );
};

const CourseApproval = props => {
  const course = props.course;

  return (
    <Fragment>
      { parse(dompurify.sanitize(course.crs_approvals)) }
    </Fragment>
  );
};

const SEARCH_RESULTS_COLUMNS = [
  {
    name: 'course_code',
    displayName: 'Course Code',
    width: 140,
    displayTransformer: course => course.course_code,
    sort: (a, b) => {
      if (a.course_code > b.course_code) return 1;
      if (a.course_code < b.course_code) return -1;
      if (a.offering_id > b.offering_id) return 1;
      if (a.offering_id < b.offering_id) return -1;
      return 0;
    },
  },

  {
    name: 'course_title',
    displayName: 'Course Title',
    displayTransformer: (course, searchParams) => <CourseTitleLink course={course} searchParams={searchParams}/>,
    sort: (a, b) => {
      if (a.course_title > b.course_title) return 1;
      if (a.course_title < b.course_title) return -1;
      if (a.offering_id > b.offering_id) return 1;
      if (a.offering_id < b.offering_id) return -1;
      return 0;
    },
  },

  {
    name: 'campus_name',
    displayName: 'Host Campus',
    width: 140,
    displayTransformer: course => course.campus_name,
    sort: (a, b) => {
      if (a.campus_name > b.campus_name) return 1;
      if (a.campus_name < b.campus_name) return -1;
      if (a.offering_id > b.offering_id) return 1;
      if (a.offering_id < b.offering_id) return -1;
      return 0;
    },
  },

  {
    name: 'instructor',
    displayName: 'Instructor',
    width: 140,
    displayTransformer: course => course.instructor,
    sort: (a, b) => {
      if (a.instructor > b.instructor) return 1;
      if (a.instructor < b.instructor) return -1;
      if (a.offering_id > b.offering_id) return 1;
      if (a.offering_id < b.offering_id) return -1;
      return 0;
    },
  },

  {
    name: 'instruction_start_date',
    displayName: 'Start Date',
    width: 110,
    displayTransformer: course => course.instruction_start_date,
    sort: (a, b) => {
      if (a.instruction_start_date > b.instruction_start_date) return 1;
      if (a.instruction_start_date < b.instruction_start_date) return -1;
      if (a.offering_id > b.offering_id) return 1;
      if (a.offering_id < b.offering_id) return -1;
      return 0;
    },
  },

  {
    name: 'open_seats',
    displayName: 'Open Seats',
    width: 110,
    displayTransformer: course => ((course.open_seats && course.open_seats > 0) ? course.open_seats : "0"),
  },

  {
    name: 'crs_approvals',
    displayName: 'Course Approvals',
    width: 160,
    displayTransformer: course => <CourseApproval course={course} />,
    sort: (a, b) => {
      if (a.crs_approvals > b.crs_approvals) return 1;
      if (a.crs_approvals < b.crs_approvals) return -1;
      if (a.offering_id > b.offering_id) return 1;
      if (a.offering_id < b.offering_id) return -1;
      return 0;
    },
  },

  {
    name: 'note',
    displayName: 'Note',
    width: 140,
    displayTransformer: course => parse(dompurify.sanitize(course.note, {ALLOWED_TAGS: ["br"]}))
  },
];

const itemsPerPageOptions =
[
  {'value' : '10', 'name': '10', 'default': false},
  {'value' : '50', 'name': '50', 'default': false},
  {'value' : '100', 'name': '100', 'default': true},
  {'value' : '2000', 'name': 'All', 'default': false}
];

class SearchPage extends Component {

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp.bind(this), false);

    const {
      fetchAndLoadSearchFilters,
      fetchInstructors,
      onSearch,
      searchParams,
      setSearchParamsValues,
    } = this.props;

    fetchAndLoadSearchFilters();
    const home_campus = searchParams.get('home_campus') !== 'null' ? searchParams.get('home_campus') : '';
    const term_year = searchParams.get('term_year') !== 'null' ? searchParams.get('term_year') : '';
    if (home_campus && term_year) {
      const search_params = {
        home_campus: home_campus,
        term_year: term_year,
        subject_area: searchParams.get('subject_area') !== 'null' ? searchParams.get('subject_area') : '',
        host_campus: searchParams.get('host_campus')  !== 'null' ? searchParams.get('host_campus') : '',
        instructor_name: searchParams.get('instructor_name')  !== 'null' ? searchParams.get('instructor_name') : '',
        with_preview: searchParams.get('with_preview')  !== 'null' ? searchParams.get('with_preview') : '',
        approvals: searchParams.get('approvals') !== 'null' ? searchParams.get('approvals').split(',') : '',
        dow: searchParams.get('dow')  !== 'null' ? searchParams.get('dow').split(',') : '',
        start_time: searchParams.get('start_time') !== 'null' ? searchParams.get('start_time') : '',
        end_time: searchParams.get('end_time') !== 'null' ? searchParams.get('end_time') : '',
        page_number: searchParams.get('page_number') !== 'null' ? parseInt(searchParams.get('page_number')) : 1,
        items_per_page: searchParams.get('items_per_page')  !== 'null' ? parseInt(searchParams.get('items_per_page')) : 10,
      };
      setSearchParamsValues(search_params);
      onSearch(search_params);
    }
    fetchInstructors();
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp, false);
  }

  onBackButton = (e) => {
    resetSearchData();
    window.history.back();
  }

  // Close modal dialogs on escape key
  handleKeyUp = (e) => {
    const { onCloseAdvancedSearch, onClose } = this.props;

    const keys = {
      27: () => {
        e.preventDefault();
        onCloseAdvancedSearch();
        onClose();
        window.removeEventListener('keyup', this.handleKeyUp, false);
      },
    };
    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
  }

  onSelectChange = (e) => {
    const { setSearchParamsValues, search_params, setErrors, errors } = this.props;
    let params = {...search_params, [e.target.name] : e.target.value, [e.target.name + "_display"] : e.target.options[e.target.selectedIndex].text};

    setSearchParamsValues(params);
    if (e.target.name === 'home_campus' && ('HOME_CAMPUS_SELECT_EMPTY_ERROR' in errors)) {
      delete errors['HOME_CAMPUS_SELECT_EMPTY_ERROR'];
      setErrors(errors);
    }

    if (e.target.name === 'term_year' && ('TERM_YEAR_SELECT_EMPTY_ERROR' in errors)) {
      delete errors['TERM_YEAR_SELECT_EMPTY_ERROR'];
      setErrors(errors);
    }
  }

  setPagination(pageNumber, itemsPerPage) {
    const { setSearchParamsValues, search_params } = this.props;
    let params = {...search_params, page_number : pageNumber, items_per_page: itemsPerPage};
    setSearchParamsValues(params);
    this.updateURL(params);
  }

  onSearch = (e) => {
    const { onSearch, search_params,  } = this.props;
    onSearch(search_params);
    if (search_params.home_campus.length > 0 && search_params.term_year.length > 0) {
      this.updateURL(search_params);
    }
  }

  updateURL(search_params) {
    const { navigate } = this.props;
    let url = search_params.home_campus === 'N/A' ? SEARCH_NONUC_ROUTE : SEARCH_RESULTS_ROUTE;
    url += "?"
    for (const [key, value] of Object.entries(search_params)) {
      if (Array.isArray(value)) {
        url += key + '=' + value.join(',') + '&';
      } else {
        url += key + '=' + value + '&';
      }
    }
    if (search_params.home_campus === 'N/A') {
      window.location.href = url;
    } else if (window.location.href.includes(SEARCH_RESULTS_ROUTE)) {
      navigate(url, { replace: true });
    } else {
      window.location.href = url;
    }
  }

  getSearchParamName(names, value) {
    for (let i = 0; i < names.length; i++) {
      if (names[i].value === value) {
        return names[i].name;
      }
    }
    return '';
  }

  getAdvancedSearchString(search_params, campuses, hours) {
    const cross_campus_approvals_mapping = {
      'ge_approval' : 'General Education',
      'pre_major_approval': 'Major Preparation',
      'major_approval' : 'Major Requirement',
      'equivalent_to_approval' : 'Course Equivalence'
    }

    let selected_criteria = [];
    if (search_params.host_campus.length > 0) {
      selected_criteria.push(this.getSearchParamName(campuses, parseInt(search_params.host_campus)));
    }

    if (search_params.dow && search_params.dow.length > 0) {
      selected_criteria.push(search_params.dow.join(', '));
    }

    const start_time = hours ? this.getSearchParamName(hours, search_params.start_time) : '';
    const end_time = hours? this.getSearchParamName(hours, search_params.end_time) : '';
    if (start_time.length > 0 && end_time.length > 0) {
      selected_criteria.push(start_time + ' - ' + end_time);
    } else if (start_time.length > 0) {
      selected_criteria.push('after ' + start_time);
    } else if (end_time.length > 0) {
      selected_criteria.push('before ' + end_time);
    }

    if (search_params.approvals) {
      search_params.approvals.forEach(item => selected_criteria.push((cross_campus_approvals_mapping[item])));
    }
    const searchedFor = selected_criteria.filter(Boolean).join(', ');
    return searchedFor.length > 0 ? "You searched for: " + searchedFor : "";
  }

  render() {
    const {
      isLoading,
      campuses,
      terms,
      subjects,
      hours,
      isSearching,
      isLoaded,
      isNonUCStudent,
      isSearchResults,
      search_results,
      search_params,
      selected_course,
      errors,
      onClose,
      show_advanced_search,
      onCloseAdvancedSearch,
      onShowAdvancedSearch,
    } = this.props;
    const home_campus_display = this.props.searchParams.get('home_campus_display')
    const initial_campuses_options = home_campus_display ? [{'name': home_campus_display, 'value': home_campus_display}] : [{'name': '- selection required -', 'value': ''}]
    const campuses_options = isLoading ? initial_campuses_options :
      [{'name': '- selection required -', 'value': ''}].concat(campuses).concat([{'name': 'Not a current UC student', 'value': 'N/A'}]);

    const term_year_display = this.props.searchParams.get('term_year_display')
    const initial_terms_options = term_year_display ? [{'name': term_year_display, 'value': term_year_display}] : [{'name': '- selection required -', 'value': ''}]
    const terms_options = isLoading ? initial_terms_options: [{'name': '- selection required -', 'value': ''}].concat(terms);

    const subjects_options = isLoading ? [{'name': 'All subject areas', 'value': ''}] : [{'name': 'All subject areas', 'value': ''}].concat(subjects);
    const homeCampusError = ('HOME_CAMPUS_SELECT_EMPTY_ERROR' in errors) ? errors['HOME_CAMPUS_SELECT_EMPTY_ERROR'] : '';
    const termYearError = ('TERM_YEAR_SELECT_EMPTY_ERROR' in errors) ? errors['TERM_YEAR_SELECT_EMPTY_ERROR'] : '';
    const isLandingPage = !(isLoaded || isSearching || isNonUCStudent || isSearchResults);
    const internalError = null // ('INTERNAL_ERROR' in errors) ? errors['INTERNAL_ERROR'] : null;
    return (
      <PageContainer className="search-results">
        <PageContent>
          <div className="cce-container" id="page">
					<div className="clear"></div>
              <div className={isLandingPage  ?  'index-search-form-container' : 'results-page-top-container'}>
                {isLandingPage ?
                  null :
                  <div className="results-page-header-container">
                  <Button className="back-btn"  onClick={this.onBackButton}>Back</Button>
                  <h1>Find Courses<br></br>and Enroll</h1>
                  </div>
                }
                {internalError &&
                    <div className='internal-error-container'>
                      <span role="alert" className="internal-error">{INTERNAL_ERROR}</span>
                    </div>}
                <Form className='cce-search-form' onSubmit={this.onSearch} key={search_params.home_campus + search_params.term_year + search_params.subject_area}>
                  <Select
                    className={makeClassName('cce-search-form-select', homeCampusError.length > 0 ? 'invalid' : '')}
                    iconClassName="cce-search-form-control-icon-container"
                    label="Select Your Campus (required):"
                    name="home_campus"
                    options={campuses_options}
                    disabled={false}
                    initialValue={search_params.home_campus}
                    onChange={this.onSelectChange}
                    errorMessage={homeCampusError}
                  />
                  <Select
                    className={makeClassName('cce-search-form-select', termYearError.length > 0 ? 'invalid' : '')}
                    iconClassName="cce-search-form-control-icon-container"
                    label="Select Term & Year (required):"
                    name="term_year"
                    options={terms_options}
                    disabled={false}
                    initialValue={search_params.term_year}
                    onChange={this.onSelectChange}
                    errorMessage={termYearError}
                  />
                  <Select
                    className="cce-search-form-select"
                    iconClassName="cce-search-form-control-icon-container"
                    label="Select Subject Area:"
                    name="subject_area"
                    options={subjects_options}
                    disabled={isSearching}
                    initialValue={search_params.subject_area}
                    onChange={this.onSelectChange}
                  />
                  <div className='cce-search-form-submit-container'>
                    <Button className={isLandingPage ? "cce-index-search-button" : "cce-search-button"} type="submit">
                      Search
                    </Button>
                    <Button className="cce-advanced-search-button" onClick={onShowAdvancedSearch}>Advanced Search</Button>
                  </div>
                </Form>
              </div>
          {
            isSearching ? <Spinner /> :
            <Fragment>
              {
                (isLoaded || errors.INTERNAL_ERROR) && !isLandingPage &&
                <Fragment>
                  <div id="advanced_search_terms" className="semi-bold">{this.getAdvancedSearchString(search_params, campuses, hours)}</div>
                  {
                  search_results.courses.length > 0 ?
                    <Table
                      className="search-table"
                      caption=""
                      columns={SEARCH_RESULTS_COLUMNS}
                      data={search_results.courses}
                      itemsPerPage={search_params.items_per_page}
                      itemsPerPageOptions={itemsPerPageOptions}
                      page={search_params.page_number}
                      setPagination={this.setPagination.bind(this)}
                      searchParams={search_params}
                    />
                    :
                    <span className="no-results-message">
                      No results matched your search. Please try again.
                      <span>
                        <br/>
                        <Button className="cce-advanced-search-button" onClick={onShowAdvancedSearch}>Adjust advanced search options</Button>
                      </span>
                    </span>
                  }
                </Fragment>
              }
            </Fragment>
          }
          { selected_course && (<CourseVideo course={selected_course} onClose={onClose}/>) }
          { show_advanced_search && (<AdvancedSearch onClose={onCloseAdvancedSearch}/>) }
        </div>
        </PageContent>
      </PageContainer>
    );
  }
}

const Search = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  return <SearchPage searchParams={searchParams} navigate={navigate} {...props}/>;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Search);