import {
  SET_LOAD_CATALOG_COURSE_DETAILS_STATUS,
  LOAD_CATALOG_COURSE_DETAILS
} from '../constants/action-types';

import {
  fetchCatalogCourseDetails,
} from '../api'

import {
  setErrors,
} from '.';

const loadCatalogCourseDetails = catalog_course_details => ({
  type: LOAD_CATALOG_COURSE_DETAILS,
  catalog_course_details
});


const setLoadCatalogCourseDetailsStatus = isLoading => (
  {
    type: SET_LOAD_CATALOG_COURSE_DETAILS_STATUS,
    isLoading
  }
);

export const fetchAndLoadCatalogCourseDetails = (course_id) => (dispatch, getState) => {
  dispatch(setLoadCatalogCourseDetailsStatus(true));
  fetchCatalogCourseDetails(course_id).then(catalog_course_details => {
    dispatch(loadCatalogCourseDetails(catalog_course_details));
  }).catch(errors => {
    dispatch(setErrors({'INTERNAL_ERROR' : errors}));
  }).finally(() => {
    dispatch(setLoadCatalogCourseDetailsStatus(false));
  });
}