import React, { Component, Fragment } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, connect } from 'react-redux';
import './style.scss';
import dompurify from 'dompurify';
import parse from 'html-react-parser'
import { makeClassName } from '../../utils';

import {
  CATALOG_COURSE_DETAILS_ROUTE,
} from "../../constants/routes";

import {
  PageContainer,
  PageContent,
} from '../../components';

import {
  Select,
  Button,
  Table,
  Spinner,
  Form,
} from '../../components/widgets';

import {
  fetchAndLoadSearchFilters,
  fetchAndLoadCatalogCourses,
  resetAllMessages,
  setErrors,
  setSelectedCourse,
  setSearchParamsValues,
  resetSearchData,
} from "../../actions"

import {
  getCampusFilterList,
  getTermFilterList,
  getSubjectAreaFilterList
} from "../../selectors";

import { INTERNAL_ERROR } from "../../constants/errors"

import CourseVideo from './course-video-modal';

const mapStateToProps = (state, props) => ({
  isLoading: state.filters.isLoading,
  campuses: getCampusFilterList(state),
  terms: getTermFilterList(state),
  subjects: getSubjectAreaFilterList(state),
  search_results: state.search.search_results,
  search_params: state.search.search_params,
  isSearching: state.search.isSearching,
  isLoaded: state.search.isLoaded,
  errors: state.messages.errors,
  selected_course: state.search.selected_course,
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSearch: search_params => dispatch(fetchAndLoadCatalogCourses({ ...search_params })),
    resetAllMessages: () => dispatch(resetAllMessages),
    setErrors: () => dispatch(setErrors),
    fetchAndLoadSearchFilters: () => dispatch(fetchAndLoadSearchFilters()),
    onCloseVideo: () => dispatch(setSelectedCourse(null)),
    setSearchParamsValues: search_params => dispatch(setSearchParamsValues(search_params)),
  };
};

const CourseTitleLink = props => {
  const { course, searchParams} = props;
  const dispatch = useDispatch();

  const onClickVideo = (selected_course) => {
    dispatch(setSelectedCourse(selected_course));
  }

  return (
    <Fragment>
      <a href={
        CATALOG_COURSE_DETAILS_ROUTE +
        `?id=` + course.course_id +
        '&subject_area=' + searchParams.subject_area +
        '&with_preview=' + searchParams.with_preview +
        '&page_number=' + searchParams.page_number +
        '&items_per_page=' + searchParams.items_per_page
        }>{course.course_title}</a>
      { course.course_video_url ?
        <div>
        <span onClick={() => onClickVideo(course)} className="course_video_icon" tabIndex="0">
          <img src={process.env.REACT_APP_CDN_URL + '/images/fa-film.png'} alt="Video"/>&nbsp;&nbsp;Video
        </span>
        </div> : ''
      }

    </Fragment>
  );
};

const SEARCH_RESULTS_COLUMNS = [
  {
    name: 'course_code',
    displayName: 'Course Code',
    width: 140,
    displayTransformer: course => course.course_code,
    sort: (a, b) => a.course_code > b.course_code ? 1 : -1,
  },

  {
    name: 'course_title',
    displayName: 'Course Title',
    displayTransformer: (course, searchParams) => <CourseTitleLink course={course} searchParams={searchParams}/>,
    sort: (a, b) => {
      if (a.course_title > b.course_title) return 1;
      if (a.course_title < b.course_title) return -1;
      if (a.course_code > b.course_code) return 1;
      if (a.course_code < b.course_code) return -1;
      return 0;
    },
  },

  {
    name: 'campus_name',
    displayName: 'Host Campus',
    width: 140,
    displayTransformer: course => course.campus_name,
    sort: (a, b) => {
      if (a.campus_name > b.campus_name) return 1;
      if (a.campus_name < b.campus_name) return -1;
      if (a.course_code > b.course_code) return 1;
      if (a.course_code < b.course_code) return -1;
      return 0;
    },
  },

  {
    name: 'course_creator',
    displayName: 'Course Creator',
    width: 140,
    displayTransformer: course => parse(dompurify.sanitize(course.course_creators)),
    sort: (a, b) => {
      if (a.course_creators > b.course_creators) return 1;
      if (a.course_creators < b.course_creators) return -1;
      if (a.course_code > b.course_code) return 1;
      if (a.course_code < b.course_code) return -1;
      return 0;
    },
  },

  {
    name: 'terms_offerred',
    displayName: 'Term(s) Offered',
    width: 160,
    displayTransformer: course => parse(dompurify.sanitize(course.terms_offerred))
  },
];

const itemsPerPageOptions =
[
  {'value' : '10', 'name': '10', 'default': false},
  {'value' : '50', 'name': '50', 'default': false},
  {'value' : '100', 'name': '100', 'default': true},
  {'value' : '2000', 'name': 'All', 'default': false}
];

class CatalogPage extends Component {

  componentDidMount() {
    const {
      fetchAndLoadSearchFilters,
      onSearch,
      searchParams,
      setSearchParamsValues,
    } = this.props;

    fetchAndLoadSearchFilters();

    const new_search_params = {
      subject_area: searchParams.get('subject_area') || '',
      with_preview: searchParams.get('with_preview') || '',
      page_number: searchParams.get('page_number') ? parseInt(searchParams.get('page_number')) : 1,
      items_per_page: searchParams.get('items_per_page') ? parseInt(searchParams.get('items_per_page')) : 10,
    };
    setSearchParamsValues(new_search_params);
    onSearch(new_search_params);
  }

  onSelectChange = (e) => {
    const { setSearchParamsValues, search_params } = this.props;
    const params = {...search_params, [e.target.name] : e.target.value};

    setSearchParamsValues(params);
  }

  setPagination(pageNumber, itemsPerPage) {
    const { setSearchParamsValues, search_params } = this.props;
    let params = {...search_params, page_number : pageNumber, items_per_page: itemsPerPage};
    setSearchParamsValues(params);
    this.updateURL(params);
  }

  onSearch = (e) => {
    const { onSearch, search_params } = this.props;
    onSearch(search_params);
    this.updateURL(search_params);
  }

  updateURL(search_params) {
    const { navigate } = this.props;
    let url  = "/search/catalog?";
    for (const [key, value] of Object.entries(search_params)) {
      if (Array.isArray(value)) {
        url += key + '=' + value.join(',') + '&';
      } else {
        url += key + '=' + value + '&';
      }
    }
    navigate(url, { replace: true });
  }

  onBackButton = (e) => {
    window.history.back();
  }

  render() {
    const {
      isLoading,
      subjects,
      isSearching,
      isLoaded,
      search_results,
      search_params,
      selected_course,
      onCloseVideo,
      errors,
    } = this.props;

    const preview_options = [{'name': 'All Courses', 'value': ''}, {'name': 'Only Courses with Preview Videos', 'value': '1'}];
    const subjects_options = isLoading ? [{'name': 'All subject areas', 'value': ''}] : [{'name': 'All subject areas', 'value': ''}].concat(subjects);
    const internalError = ('INTERNAL_ERROR' in errors) ? errors['INTERNAL_ERROR'] : '';
    return (
      <PageContainer className="search-results">
        <PageContent>
          <div className="cce-container" id="page">
					<div className="clear"></div>
            <div className="catalog-page-top-container">
                <div className="catalog-info-container">
                  <div className="catalog-main-content">
                    {internalError &&
                      <div className='internal-error-container'>
                        <span role="alert" className="internal-error">{INTERNAL_ERROR}</span>
                      </div>}
                    <Form className='cce-search-form' onSubmit={this.onSearch} key={search_params.home_campus + search_params.term_year + search_params.subject_area}>
                      <Select
                        className="cce-catalog-form-input-container"
                        iconClassName="cce-catalog-form-control-icon-container"
                        label="Select subject area:"
                        name="subject_area"
                        options={subjects_options}
                        disabled={isSearching}
                        initialValue={search_params.subject_area}
                        onChange={this.onSelectChange}
                      />
                      <Select
                        className="cce-catalog-form-input-container"
                        iconClassName="cce-catalog-form-control-icon-container"
                        label="Courses with video preview:"
                        name="with_preview"
                        options={preview_options}
                        disabled={isSearching}
                        initialValue={search_params.with_preview}
                        onChange={this.onSelectChange}
                      />
                      <div className='cce-search-form-submit-container'>
                        <Button className="cce-search-button" type="submit">
                          Search
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
            </div>
          {
            isSearching ? <Spinner /> :
            <Fragment>
              {
                isLoaded ?
                  search_results.courses.length > 0 ?
                    <Table
                      className="search-table"
                      caption=""
                      columns={SEARCH_RESULTS_COLUMNS}
                      data={search_results.courses}
                      itemsPerPage={search_params.items_per_page}
                      itemsPerPageOptions={itemsPerPageOptions}
                      page={search_params.page_number}
                      setPagination={this.setPagination.bind(this)}
                      searchParams={search_params}
                    />
                    :
                    <span className="no-results-message">No results matched your search. Please try again.</span>
                :
                null
              }
            </Fragment>
          }
          { selected_course && (<CourseVideo course={selected_course} onClose={onCloseVideo}/>) }
        </div>
        </PageContent>
      </PageContainer>
    );
  }
}

const Catalog = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  return <CatalogPage searchParams={searchParams} navigate={navigate} {...props}/>;
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Catalog);