import {
  SEARCH_COURSES,
  SEARCH_CATALOG,
  SET_SEARCHING_STATUS,
  SET_LOADED_STATUS,
  SET_SEARCH_PARAMS,
  SET_SELECTED_COURSE,
  SET_NON_UC_STUDENT,
  SET_SEARCH_RESULTS,
  SHOW_NOTIFY_ME,
  SHOW_ADVANCED_SEARCH,
  SET_NOTIFY_ME_PARAMS,
} from '../constants/action-types';

import {
  fetchSearchResults,
  fetchCatalogResults,
  notifyMe
} from '../api'

import {
  makeGenericErrorMessage,
  HOME_CAMPUS_SELECT_EMPTY_ERROR,
  TERM_YEAR_SELECT_EMPTY_ERROR
} from '../constants/errors';

import {
  setErrors,
  setSuccessMessage,
  resetAllMessages
} from '.';

const searchCourses = search_results => ({
  type: SEARCH_COURSES,
  search_results
});

const searchCatalog = search_results => ({
  type: SEARCH_CATALOG,
  search_results
});


const setSearchingStatus = isSearching => (
  {
    type: SET_SEARCHING_STATUS,
    isSearching
  }
);

const setLoadedStatus = isLoaded => (
  {
    type: SET_LOADED_STATUS,
    isLoaded
  }
);

const setNonUCStudent = isNonUCStudent => (
  {
    type: SET_NON_UC_STUDENT,
    isNonUCStudent
  }
);

const setSearchResults = isSearchResults => (
  {
    type: SET_SEARCH_RESULTS,
    isSearchResults
  }
);

const setSearchParams = search_params => (
  {
    type: SET_SEARCH_PARAMS,
    search_params
  }
);

const selectedCourse = selected_course => (
  {
    type: SET_SELECTED_COURSE,
    selected_course
  }
);

const showAdvancedSearch = show_advanced_search => (
  {
    type: SHOW_ADVANCED_SEARCH,
    show_advanced_search
  }
);

const showNotifyMe= show_notify_me => (
  {
    type: SHOW_NOTIFY_ME,
    show_notify_me
  }
);

export const resetSearchResults = () => ({
  type: SEARCH_COURSES,
  search_results: {
    courses: []
  },
});

export const setShowAdvancedSearch = (show_advanced_search) => (dispatch) => {
  dispatch(resetAllMessages());
  dispatch(showAdvancedSearch(show_advanced_search));
}

export const setShowNotifyMe = (show_notify_me) => (dispatch) => {
  dispatch(resetAllMessages());
  dispatch(showNotifyMe(show_notify_me));
}

export const setSelectedCourse = (selected_course) => (dispatch) => {
  dispatch(selectedCourse(selected_course));
}

export const setSearchParamsValues = (search_params) => (dispatch) => {
  dispatch(setSearchParams(search_params));
}

export const fetchAndLoadSearchCourses = (search_params = null, force = false) => (dispatch, getState) => {
  dispatch(resetAllMessages());
  if (search_params === null) {
    search_params = getState().search.search_params;
  } else {
    dispatch(setSearchParams(search_params));
  }

  let errors = {};
  if (search_params.home_campus.length === 0) {
    errors['HOME_CAMPUS_SELECT_EMPTY_ERROR'] = HOME_CAMPUS_SELECT_EMPTY_ERROR;
  }

  if (search_params.term_year.length === 0) {
    errors['TERM_YEAR_SELECT_EMPTY_ERROR'] = TERM_YEAR_SELECT_EMPTY_ERROR;
  }

  if (search_params.home_campus.length === 0 || search_params.term_year.length === 0) {
    console.log('in here');
    dispatch(setErrors(errors));
    return false;
  }

  dispatch(resetSearchResults());
  dispatch(setNonUCStudent(false));
  dispatch(setLoadedStatus(false));

  if (search_params.home_campus === 'N/A') {
    dispatch(setNonUCStudent(true));
    dispatch(showAdvancedSearch(false));
    return false;
  }

  const search_results = getState().search.search_results;
  if (search_results.isSearching && !force) {
    return false;
  }

  dispatch(showAdvancedSearch(false));
  dispatch(setSearchingStatus(true));
  //dispatch(setSearchResults(true));
  fetchSearchResults(search_params).then(search_results => {
    dispatch(searchCourses(search_results));
    dispatch(setLoadedStatus(true));
  }).catch(errors => {
    dispatch(setErrors({'INTERNAL_ERROR' : errors}));
    dispatch(setLoadedStatus(false));
  }).finally(() => {
    dispatch(setSearchingStatus(false));
  });
}

export const fetchAndLoadCatalogCourses = (search_params = null, force = false) => (dispatch, getState) => {
  dispatch(resetAllMessages());
  if (search_params === null) {
    search_params = getState().search.search_params;
  }

  dispatch(setLoadedStatus(false));

  const search_results = getState().search.search_results;
  if (search_results.isSearching && !force) {
    return false;
  }

  dispatch(setSearchParams(search_params));
  dispatch(setSearchingStatus(true));
  fetchCatalogResults(search_params).then(search_results => {
    dispatch(searchCatalog(search_results));
    dispatch(setLoadedStatus(true));
  }).catch(errors => {
    dispatch(setErrors({'INTERNAL_ERROR' : errors}));
    dispatch(setLoadedStatus(false));
  }).finally(() => {
    dispatch(setSearchingStatus(false));
  });
}

export const resetSearchData = () => (dispatch) =>{
  dispatch(setLoadedStatus(false));
  dispatch(resetAllMessages());
  dispatch(setSearchingStatus(false));
  dispatch(setNonUCStudent(false));
}


export const fetchNotifyMe = (data) => (dispatch) => {
  dispatch(resetAllMessages());
  notifyMe(data).then(message => {
    dispatch(setSuccessMessage(message));
  }).catch(errors => {
    dispatch(setErrors({'INTERNAL_ERROR' : errors}));
  }).finally(() => {
  });
}

export const setNotifyMeParams = (params) => (dispatch) => {
  dispatch(
    {
      type: SET_NOTIFY_ME_PARAMS,
      params
    }
  );
}