import {
  SET_NOTIFY_ME_PARAMS
} from '../constants/action-types';

const initialState = {
  data: {
    first_name: '',
    last_name: '',
    email: '',
    term: [],
  }
};

export default function notifyMe(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFY_ME_PARAMS:
      return {
        ...state,
        data: action.params,
      };
    default:
      return state;
  }
}