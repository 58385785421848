import React, { Fragment, useState } from "react";
import "./style.scss";
import dompurify from 'dompurify';
import parse from 'html-react-parser'

import SectionMeetingTimes from "./section-meeting-times";

const CourseCreditHeader = props => {
  const {course_approvals} = props;
  const home_campus_name = Object.keys(course_approvals).length > 0 ? Object.keys(course_approvals)[0] : '';
  const home_campus_approvals = course_approvals[home_campus_name];
  return <Fragment>
    <h3 className="course_header">COURSE CREDIT</h3>
    <h4>{home_campus_name + ':'}</h4>
    <CourseApproval course_approval={home_campus_approvals}/><br/>
  </Fragment>
};

const CourseApproval = props => {
  const {course_approval} = props;
  return <Fragment>
  {Array.isArray(course_approval) ?
    course_approval.map((approval, index) => (
      <Fragment key={index}>
      <span className="course_approval_content mobile_small_text">{parse(dompurify.sanitize(approval, {ALLOWED_TAGS: []}))}</span><br/>
      </Fragment>
    )) :
    Object.keys(course_approval).map((approval, index) => (
      <Fragment key={index}>
        {!isNaN(+approval) ?
          <span className="course_approval_content mobile_small_text">{parse(dompurify.sanitize(course_approval[approval]))}</span>
          : <span className="course_approval_content mobile_small_text">{parse(dompurify.sanitize(approval, {ALLOWED_TAGS: []}))}: {parse(dompurify.sanitize(course_approval[approval], {ALLOWED_TAGS: []}))}</span>
        }
        <br/>
      </Fragment>
    ))
  }
  </Fragment>
}

const OtherCourseApprovals = props => {
  const {course_approvals} = props;
  return <Fragment>
    <div id="other_approvals">
      {Object.keys(course_approvals).map((approval, index) => (
        index > 0 ? <Fragment key={index}>
          <h4 className="course-approval-campus">{approval}:</h4>
          <CourseApproval course_approval={course_approvals[approval]}/>
        </Fragment> : null
        ))
      }
    </div>
  </Fragment>
}

const EnrollmentInformation = props => {
    const { course_offering_details } = props;
    const [showOtherApprovals, setShowOtherApprovals] = useState(false);
    const onShowOtherUCs = () => setShowOtherApprovals(!showOtherApprovals)
    const course = course_offering_details.course;
    const course_approvals = course_offering_details.course_approvals;
    return <Fragment>
      <div id="enrollment_info" className="mobile_small_text">
        <div className="x-campus_info x-campus_info_search mobile_small_text">
          <div className="x-campus_info_pic mobile_hide"><img src={process.env.REACT_APP_CDN_URL + "/images/catalog-student-laptop.jpg"} alt="Student studying with laptop"/></div>
          <div>
            <CourseCreditHeader course_approvals={course_approvals} />
            <div className="course_credit_description">{parse(dompurify.sanitize(course.credit_description))}</div><br/>
            <div className="print-hide"><br/><button id="show_other_approvals" onClick={onShowOtherUCs}>{showOtherApprovals ? 'Less information' : 'Other UCs'}</button></div><br/>
            {showOtherApprovals &&
              <OtherCourseApprovals course_approvals={course_approvals}/>
            }
          </div>
        </div>
        <SectionMeetingTimes course_offering_details={course_offering_details}></SectionMeetingTimes>
        {course.prerequisites ?
          <Fragment>
            <h4>Prerequisites</h4>
            <p>
              {course.prerequisites}
            </p>
          </Fragment> : null
        }

        {course_offering_details.offering.meeting_requirements ?
          <Fragment>
            <h4>Course Meeting Requirements</h4>
            <p>
              {parse(dompurify.sanitize(course_offering_details.offering.meeting_requirements))}
            </p>
          </Fragment> : null
        }

        {course.additional_info ?
          <Fragment>
            <h4>More About The Course</h4>
            <p>
              {parse(dompurify.sanitize(course.additional_info))}
            </p>
          </Fragment> : null
        }
      </div>
    </Fragment>
};

export default EnrollmentInformation;