import React, { Fragment } from "react";
import "./style.scss";
import { connect } from 'react-redux';
import moment from "moment-timezone";

import {
  toogleShowAllAvailableSections,
} from "../../../actions"

const mapStateToProps = (state, props) => ({
  showAllSections: state.courseOfferingDetails.showAllSections,
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    setShowAllAvailableSections: () => dispatch(toogleShowAllAvailableSections()),
  };
};

function UTCToPSTMeetingTime(utcDate, utcStringStart, utcStringEnd) {
  var weekNumber = moment(new Date()).format('WW');
  var startDate = moment().day(utcDate).week(weekNumber);
  var start = moment.utc(startDate.format('yyyy-MM-DD') + ' ' + utcStringStart).tz("America/Los_Angeles");
  var end = moment.utc(startDate.format('yyyy-MM-DD') + ' ' + utcStringEnd).tz("America/Los_Angeles");
  return start.format('dddd, h:mm a') + ' - ' + end.format('h:mm a');
}

function getAsyncMeeting(section) {
  let asyncText = "Asynchronous Section - ";
  if (section.campus_section_number && section.campus_section_number.length > 0) {
    asyncText += section.campus_section_number;
  } else {
    asyncText += section.campus_section_crn ? section.campus_section_crn : '';
  }
  return asyncText;
}

function GetMeetingTimes(section) {
  let meetingTimes = [];
  section.sectionMeetings.map((sectionMeeting, index) => (
    IsScheduled(section) ? meetingTimes.push(UTCToPSTMeetingTime(sectionMeeting.schedule_day, sectionMeeting.schedule_begin_time, sectionMeeting.schedule_end_time))
    : meetingTimes.push(getAsyncMeeting(section))
  ))
  return meetingTimes.join('; ')
}

function IsScheduled(section) {
  let sectionMeeting = (Array.isArray(section.sectionMeetings) && section.sectionMeetings.length > 0) ? section.sectionMeetings[0] : null;
  if (sectionMeeting === 'undefined' ||
    sectionMeeting.schedule_day.length === 0 ||
    sectionMeeting.schedule_day === 'Sunday' ||
    sectionMeeting.schedule_begin_time.length === 0 ||
    sectionMeeting.schedule_end_time.length === 0) {
      return false;
  }
  return true;
}

const SectionMeetingTimes = props => {
    const { course_offering_details, showAllSections, setShowAllAvailableSections} = props;
    const course = course_offering_details.course;
    const sections = course_offering_details.sections;
    const home_campus = course_offering_details.home_campus;
    return <Fragment>
      <div id='section_meeting_times'>
        <h4>Section Meeting Times</h4>
      </div>
      {(sections.length > 1) &&
        <p>
          <button id="list_all_sections" onClick={setShowAllAvailableSections}>{showAllSections ? 'Hide sections' : 'Show all sections'}</button>
        </p>
      }
      {
        (sections.length > 1) ?
          showAllSections &&
          <div id='all_sections'>
            <ul>
              {
                sections.map((section, index) => (
                  <li key={index}>
                    ({section.section_type})&nbsp;
                    {
                      GetMeetingTimes(section)
                    }
                    {
                      (home_campus.campus_id !== course.host_campus_id && course_offering_details.open_for_enrollment) ?
                      IsScheduled(section) ?
                        <span><a cn-data={course.course_code} href={section.enroll_url + "&campus=" + home_campus.campus_alias} target="#">Enroll<span className="offscreen">(opens in a new tab)</span></a></span>
                        :
                        <span><a cn-data={course.course_code} href={section.enroll_url + "&campus=" + home_campus.campus_alias} target="#">Enroll (No meeting day required)<span className="offscreen">(opens in a new tab)</span></a></span>
                      : null
                    }
                  </li>
                ))
              }
            </ul>
          </div>
        : sections[0].sectionMeetings.length > 1 ?
          <ul>
            {
              sections[0].sectionMeetings.map((sectionMeeting, index) => (
                <li key={index}>
                  ({sections[0].section_type})&nbsp; {UTCToPSTMeetingTime(sectionMeeting.schedule_day, sectionMeeting.schedule_begin_time, sectionMeeting.schedule_end_time)}
                </li>
              ))
            }
          </ul> :
          <p>({sections[0].section_type})&nbsp;{GetMeetingTimes(sections[0])}</p>
      }
    </Fragment>
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SectionMeetingTimes);