export const SEARCH_COURSES = 'SEARCH_COURSES';
export const SEARCH_CATALOG = 'SEARCH_CATALOG';
export const SET_SEARCHING_STATUS = 'SET_SEARCHING_STATUS';
export const SET_LOADED_STATUS = 'SET_LOADED_STATUS';
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';
export const SET_SELECTED_COURSE = 'SET_SELECTED_COURSE';
export const SET_NON_UC_STUDENT = 'SET_NON_UC_STUDENT';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SHOW_ADVANCED_SEARCH = 'SHOW_ADVANCED_SEARCH';
export const SHOW_NOTIFY_ME = 'SHOW_NOTIFY_ME';
export const SET_NOTIFY_ME_PARAMS = 'SET_NOTIFY_ME_PARAMS';
