import {
  SEARCH_COURSES,
  SEARCH_CATALOG,
  SET_SEARCHING_STATUS,
  SET_SEARCH_PARAMS,
  SET_SELECTED_COURSE,
  SET_NON_UC_STUDENT,
  SET_LOADED_STATUS,
  SHOW_ADVANCED_SEARCH,
  SET_SEARCH_RESULTS,
  SHOW_NOTIFY_ME,
} from '../constants/action-types';

const initialState = {
  search_params: {
    home_campus: '',
    term_year: '',
    subject_area: '',
    host_campus: '',
    instructor_name: '',
    with_preview: '',
    approvals: [],
    dow: [],
    start_time: '',
    end_time: '',
    current_page: 1,
    items_per_page: 10,
  },
  search_results: {
    courses: []
  },
  selected_course: null,
  isSearching: false,
  isLoaded: false,
  isNonUCStudent: false,
  isSearchResults: false,
  show_advanced_search: false,
  show_notify_me: false,
};

export default function search(state = initialState, action) {
  switch (action.type) {

    case SEARCH_COURSES:
      return {
        ...state,
        search_results: action.search_results,
        isLoaded: true,
      };

    case SEARCH_CATALOG:
      return {
        ...state,
        search_results: action.search_results,
        isLoaded: true,
      };

    case SET_SEARCHING_STATUS:
      return {
        ...state,
        isSearching: action.isSearching
      };

    case SET_SEARCH_PARAMS:
      return {
        ...state,
        search_params: action.search_params
      };

    case SET_SELECTED_COURSE:
      return {
        ...state,
        selected_course: action.selected_course
      };

    case SET_LOADED_STATUS:
      return {
        ...state,
        isLoaded: action.isLoaded
      };

    case SET_NON_UC_STUDENT:
      return {
        ...state,
        isNonUCStudent: action.isNonUCStudent
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        isSearchResults: action.isSearchResults
      };

    case SHOW_ADVANCED_SEARCH:
      return {
        ...state,
        show_advanced_search: action.show_advanced_search
      };

    case SHOW_NOTIFY_ME:
      return {
        ...state,
        show_notify_me: action.show_notify_me
      };
    default:
      return state;
  }
}