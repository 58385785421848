import {
  SET_LOAD_COURSE_OFFERING_DETAILS_STATUS,
  LOAD_COURSE_OFFERING_DETAILS,
  SET_SHOW_ALL_SECTIONS
} from '../constants/action-types';

import {
  fetchCourseOfferingDetails
} from '../api'

import {
  setErrors,
  setSuccessMessage,
  resetAllMessages
} from '.';

const loadCourseOfferingDetails = course_offering_details => ({
  type: LOAD_COURSE_OFFERING_DETAILS,
  course_offering_details
});


const setLoadCourseOfferingDetailsStatus = isLoading => (
  {
    type: SET_LOAD_COURSE_OFFERING_DETAILS_STATUS,
    isLoading
  }
);

const setShowAllSections = showAllSections => (
  {
    type: SET_SHOW_ALL_SECTIONS,
    showAllSections
  }
);

export const setShowAllAvailableSections = (showAllSections) => (dispatch) => {
  dispatch(setShowAllSections(showAllSections));
}

export const toogleShowAllAvailableSections = () => (dispatch, getState) => {
  dispatch(setShowAllSections(!getState().courseOfferingDetails.showAllSections));
}

export const fetchAndLoadCourseOfferingDetails = (offering_id, home_campus_id) => (dispatch, getState) => {
  dispatch(setLoadCourseOfferingDetailsStatus(true));
  fetchCourseOfferingDetails(offering_id, home_campus_id).then(course_offering_details => {
    dispatch(loadCourseOfferingDetails(course_offering_details));
  }).catch(errors => {
    dispatch(setErrors({'INTERNAL_ERROR' : errors}));
  }).finally(() => {
    dispatch(setLoadCourseOfferingDetailsStatus(false));
  });
}