import {
  LOAD_SEARCH_FILTERS,
  LOAD_SEARCH_INSTRUCTORS,
  SET_SEARCH_FILTERS_LOADING_STATUS,
  SET_SHOW_ALERT
} from '../constants/action-types';

const initialState = {
  data: {
    campus_filter: [],
    term_filter: [],
    subject_area_filter: [],
    hours_filter: [],
    instructors_filter: [],
  },
  loaded: false,
  isLoading: true,
  showAlert: true,
};

export default function filters(state = initialState, action) {
  switch (action.type) {

    case LOAD_SEARCH_FILTERS:
      return {
        ...state,
        data: action.filters,
        loaded: true,
      };

    case LOAD_SEARCH_INSTRUCTORS:
      return {
        ...state,
        instructors: action.instructors,
      };

    case SET_SEARCH_FILTERS_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case SET_SHOW_ALERT:
      return {
        ...state,
        showAlert: action.showAlert
      };
    default:
      return state;
  }
}