import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.scss";
import FocusTrap from "focus-trap-react";

import { makeClassName } from "../../utils";

import { setModalMessageStatus } from "../../actions";
import store from "../../store";

class Modal extends Component {
  componentDidMount() {
    store.dispatch(setModalMessageStatus(true));
  }

  componentWillUnmount() {
    store.dispatch(setModalMessageStatus(false));
  }

  render() {
    const { handleClose, children, id, className } = this.props;
    return (
      <div
        id={id}
        className={makeClassName("modal", "display-block", className)}
      >
        <FocusTrap>
          <section className="modal-main" role="alert">
            {children}
            <button className="close" onClick={handleClose}>
            </button>
          </section>
        </FocusTrap>
      </div>
    );
  }
}

Modal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  handleClose: PropTypes.func
};

export default Modal;
