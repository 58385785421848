import {
  SET_ERRORS,
  PUSH_ERRORS,
  SET_SUCCESS_MESSAGE,
  SET_MODAL_MESSAGE_STATUS
} from '../constants/action-types';

const initialState = {
  inModal: false,
  errors: {},
  successMessage: null
};

export default function messages(state = initialState, action) {
  const newErrors = action.errors;
  switch (action.type) {
    case SET_ERRORS:
      return { ...state, errors: newErrors };
    case PUSH_ERRORS:
      return { ...state, errors: state.errors.concat(newErrors) };
    case SET_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.successMessage };
    case SET_MODAL_MESSAGE_STATUS:
      return { ...state, inModal: action.inModal };
    default:
      return state;
  }
}
