import React, { Component, Fragment, useLocation } from 'react';
import { useSearchParams } from "react-router-dom";
import { useDispatch, connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import '../style.scss';
import './style.scss';
import dompurify from 'dompurify';
import parse from 'html-react-parser'

import {
  CATALOG_ROUTE,
} from "../../../constants/routes";

import {
  PageContainer,
  PageContent,
} from '../../../components';

import {
  Spinner,
  Button,
} from '../../../components/widgets';

import {
  fetchAndLoadCatalogCourseDetails,
  resetAllMessages,
  setErrors,
  setShowNotifyMe,
} from "../../../actions"

import KeyInformation from './key-information';
import EnrollmentInformation from './enrollment-information';
import FacultyInformation from './faculty-information';
import NotifyMeModal from './notify-me-modal';

const mapStateToProps = (state, props) => ({
  isLoading: state.catalogCourseDetails.isLoading,
  isLoaded: state.catalogCourseDetails.loaded,
  catalog_course_details: state.catalogCourseDetails.catalog_course_details,
  errors: state.messages.errors,
  show_notify_me: state.search.show_notify_me,
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchAndLoadCatalogCourseDetails: (course_id) => dispatch(fetchAndLoadCatalogCourseDetails(course_id)),
    resetAllMessages: () => dispatch(resetAllMessages),
    setErrors: () => dispatch(setErrors),
    onCloseNotifyMe: () => dispatch(setShowNotifyMe(false)),
    onShowNotifyMe: () => dispatch(setShowNotifyMe(true)),
  };
};

const CourseVideo= props => {
  return (
    (props.display_video && props.display_video.length > 0) &&
      <div id="video_plus_text_container">
        <div className='catalog_video'>
          <div className='video_wrapper'>
            {parse(dompurify.sanitize(props.display_video, { ADD_TAGS: ["iframe"], ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'] }))}
          </div>
        </div>
      </div>
  );
};

class CatalogCourseDetailsPage extends Component {

  componentDidMount() {
    this.props.fetchAndLoadCatalogCourseDetails(this.props.searchParams.get('id'));
  }

  getCatalogURL = () => {
    const { searchParams } = this.props;
    const url = CATALOG_ROUTE + '?' +
      '&subject_area=' + searchParams.get('subject_area') +
      '&with_preview=' + searchParams.get('with_preview') +
      '&page_number=' + searchParams.get('page_number') || 1 +
      '&items_per_page=' + searchParams.get('items_per_page' || 10);
    return url;
  }

  onBackButton = (e) => {
    window.location.href = this.getCatalogURL();
  }

  render() {
    const {
      isLoaded,
      isLoading,
      catalog_course_details,
      show_notify_me,
      onCloseNotifyMe,
      onShowNotifyMe,
      searchParams,
    } = this.props;
    const course = catalog_course_details.course;

    return (
      <PageContainer className="search-results">
        <Helmet>
          {isLoaded &&
            <title>{course.course_code}: {course.course_title} at {catalog_course_details.host_campus.campus_name} | UC Online</title>
          }
        </Helmet>
        <PageContent>
          {isLoading && <Spinner />}
          {isLoaded &&
            <Fragment>
              <Button className="back-btn"  onClick={this.onBackButton}>&nbsp;Back to Catalog Results</Button>
              <div id='course_view'>
                <h1><span id="catalog_header">Catalog</span></h1>
                <div id='course_info'>
                  <h2>{course.course_title}, {course.course_code}</h2>
                  <div id='catalog_course_info'>
                    <CourseVideo display_video={catalog_course_details.display_video}/>
                    <KeyInformation catalog_course_details={catalog_course_details} onShowNotifyMe={onShowNotifyMe}/>
                  </div>
                </div>
                <EnrollmentInformation catalog_course_details={catalog_course_details}/>
                <FacultyInformation course_creators={catalog_course_details.course_creators}></FacultyInformation>
              </div>
            </Fragment>
          }
          { show_notify_me && (<NotifyMeModal onCloseNotifyMe={onCloseNotifyMe} course={course} terms={catalog_course_details.terms} returnURL={this.getCatalogURL(searchParams)}/>) }
        </PageContent>
      </PageContainer>
    );
  }
}


const CatalogCourseDetails = (props) => {
  const [searchParams] = useSearchParams();
  return <CatalogCourseDetailsPage searchParams={searchParams} {...props}/>;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatalogCourseDetails);