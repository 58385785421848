import React, { Fragment } from "react";
import "./style.scss";

const AdditionalInformation = props => {
  const { offering, course } = props;
  return <Fragment>
    <div id="additional_info" className="mobile_small_text">
      {offering.exam_info && offering.proctoring_info && course.course_url &&
        <h4 id="addl_crs_info">Additional Course Information</h4>
      }
      {offering.exam_info &&
        <Fragment>
          <p>
            <span><strong>Exam Info</strong></span><br />
            {offering.exam_info}
          </p>
        </Fragment>
      }
      {offering.proctoring_info &&
        <Fragment>
          <p>
          <span><strong>Proctoring Info</strong></span><br />
            {offering.proctoring_info}
          </p>
        </Fragment>
      }
      {course.course_url &&
        <Fragment>
          <p>
            <a href={course.course_url} target="_blank" rel="noreferrer" >Relevant Website</a>
          </p>
        </Fragment>
      }
    </div>
  </Fragment>
};

export default AdditionalInformation;