import React, { Fragment } from "react";
import "./style.scss";

const Tooltip = props => {
  return (
    <Fragment>
      <div className="tooltip" tabIndex="0" role="tooltip">
        ?<span className="tooltiptext">{props.children}</span>
      </div>
    </Fragment>
  );
};

export default Tooltip;
