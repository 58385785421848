import React, { Fragment, useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { Button, Form, Select } from "../../components/widgets";
import { makeClassName } from '../../utils';
import { Typeahead } from 'react-bootstrap-typeahead';

import {
  SEARCH_RESULTS_ROUTE,
  SEARCH_NONUC_ROUTE,
} from "../../constants/routes";

import {
  fetchAndLoadSearchCourses,
  resetAllMessages,
  setSearchParamsValues,
  setShowAdvancedSearch,
  setErrors,
} from "../../actions"

import {
  getCampusFilterList,
  getTermFilterList,
  getSubjectAreaFilterList,
  getHoursFilterList,
} from "../../selectors";

import "./style.scss";
import 'react-bootstrap-typeahead/css/Typeahead.css';

const mapStateToProps = (state, props) => ({
  campuses: getCampusFilterList(state),
  instructors: state.filters.instructors,
  errors: state.messages.errors,
  hours: getHoursFilterList(state),
  subjects: getSubjectAreaFilterList(state),
  terms: getTermFilterList(state),
  search_params: state.search.search_params,
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSearch: (search_params) => dispatch(fetchAndLoadSearchCourses(search_params)),
    clearSearchParams: () => dispatch(setSearchParamsValues({})),
    setSearchParamsValues: search_params => dispatch(setSearchParamsValues(search_params)),
    resetAllMessages: () => dispatch(resetAllMessages),
    setErrors: () => dispatch(setErrors),
    onCloseAdvancedSearch: () => dispatch(setShowAdvancedSearch(false)),
  };
};

function ClickOutside(ref) {
  const dispatch = useDispatch();
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(setShowAdvancedSearch(false));
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, dispatch]);
}

const AdvancedSearchModal = (props) => {
  const navigate = useNavigate();

  const onSelectChange = (e) => {
    const { setErrors, errors } = props;
    if (e.target.name === 'home_campus' && ('HOME_CAMPUS_SELECT_EMPTY_ERROR' in errors)) {
      delete errors['HOME_CAMPUS_SELECT_EMPTY_ERROR'];
      setErrors(errors);
    }

    if (e.target.name === 'term_year' && ('TERM_YEAR_SELECT_EMPTY_ERROR' in errors)) {
      delete errors['TERM_YEAR_SELECT_EMPTY_ERROR'];
      setErrors(errors);
    }
  }

  const InstructorSearch = (props) => {
    const [invalidName, setInvalidName] = useState(false);
    const OnInstructorInputBlur = (e) => {
      const value = e.target.value;
      if ((value.length > 0) &&
          (!/^([a-zA-ZáéíèàùñÑêÊäÄöÖüÜß\-.()]+\s)*[a-zA-ZáéíèàùñÑêÊäÄöÖüÜß\-.()']+$/.test(value))) {
        setInvalidName(true);
      } else {
        setInvalidName(false);
      }
    }

    return(
      <Fragment>
        <label className={makeClassName("ucop-text-input cce-search-form-input-container instructor-input", invalidName ? 'invalid' : '')}>
        Instructor Name:
        <Typeahead
          id="instructor_name"
          inputProps={{'name' : 'instructor_name'}}
          minLength={1}
          options={props.instructors}
          defaultInputValue={props.instructor_name}
          onBlur={(e) => OnInstructorInputBlur(e)}
          emptyLabel="No instructor found"
        />
          <span role="alert" className="input-field-error-container">Invalid name</span>
        </label>
      </Fragment>
    )
  }

  const onClear = (e) => {
    const { setSearchParamsValues, search_params } = props;
    const reset_search_params = {
      home_campus: search_params.home_campus,
      term_year: search_params.term_year,
      subject_area: '',
      instructor_name: '',
      approvals: [],
      dow: [],
      start_time: '',
      end_time: '',
      host_campus: '',
    }
    setSearchParamsValues(reset_search_params);
  }

  const myRef = useRef(null)
  ClickOutside(myRef);

  const scrollToElement = () => myRef.current.scrollIntoView()

  const focusRef = useRef(null);
  useEffect(() => {
    focusRef.current.focus();
  });

  const OnSearchButton = (data) => {
    const { onSearch, search_params } = props;
    data = {...data,
      current_page: search_params.current_page ? search_params.current_page : 1,
      items_per_page: search_params.items_per_page ? search_params.items_per_page : 10
    }
    onSearch(data)

    scrollToElement();
    let url = data.home_campus === 'N/A' ? SEARCH_NONUC_ROUTE : SEARCH_RESULTS_ROUTE;
    url += "?"
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        url += key + '=' + value.join(',') + '&';
      } else {
        url += key + '=' + value + '&';
      }

    }
    window.location.href = url;
  }

  const {
    campuses,
    instructors,
    errors,
    hours,
    onCloseAdvancedSearch,
    search_params,
    subjects,
    terms,
  } = props;

  const home_campus_options = [{'name': '- selection required -', 'value': ''}].concat(campuses).concat([{'name': 'Not a current UC student', 'value': 'N/A'}]);
  const terms_options = [{'name': '- selection required -', 'value': ''}].concat(terms);
  const subjects_options = [{'name': 'All subject areas', 'value': ''}].concat(subjects);
  const host_campus_options = [{'name': '- select -', 'value': ''}].concat(campuses);
  const course_with_preview_video_options = [{'name':'All Courses', 'value':''}, {'name':'Only Courses with Preview Videos','value':'1'}];
  const time_range_options = [{'name': '-', 'value': ''}].concat(hours);
  const homeCampusError = ('HOME_CAMPUS_SELECT_EMPTY_ERROR' in errors) ? errors['HOME_CAMPUS_SELECT_EMPTY_ERROR'] : '';
  const termYearError = ('TERM_YEAR_SELECT_EMPTY_ERROR' in errors) ? errors['TERM_YEAR_SELECT_EMPTY_ERROR'] : '';
  return (
    <Fragment>
      <div className="cce-advanced-search-modal-backdrop"></div>
      <div className="cce-advanced-search-modal-container" ref={myRef}>
        <div className="cce-advanced-search-modal-content">
          <div className="cce-advanced-search-modal-header-row">
            <span className="cce-advanced-search-modal-title">Advanced Search Options</span>
            <button className="cce-advanced-search-modal-close-button" onClick={onCloseAdvancedSearch}><span className="sr_hide">close advanced search</span></button>
          </div>
          <Form onSubmit={OnSearchButton} key={Math.random()} className="cce-advanced-search-form">
            <Select
              className={makeClassName('cce-search-form-select', homeCampusError.length > 0 ? 'invalid' : '')}
              iconClassName="cce-advanced-search-form-control-icon-container"
              label="Select Your Campus (required):"
              name="home_campus"
              options={home_campus_options}
              disabled={false}
              initialValue={search_params.home_campus}
              focusRef={focusRef}
              onChange={onSelectChange}
              errorMessage={homeCampusError}
            />
            <Select
              className={makeClassName('cce-search-form-select', termYearError.length > 0 ? 'invalid' : '')}
              iconClassName="cce-advanced-search-form-control-icon-container"
              label="Select Term & Year (required):"
              name="term_year"
              options={terms_options}
              disabled={false}
              initialValue={search_params.term_year}
              onChange={onSelectChange}
              errorMessage={termYearError}
            />
            <Select
              className={makeClassName('cce-search-form-select')}
              iconClassName="cce-advanced-search-form-control-icon-container"
              label="Select Subject Area:"
              name="subject_area"
              options={subjects_options}
              disabled={false}
              initialValue={search_params.subject_area}
              onChange={onSelectChange}
            />
            <Select
              className={makeClassName('cce-search-form-select')}
              iconClassName="cce-advanced-search-form-control-icon-container"
              label="Host Campus Offering Course:"
              name="host_campus"
              options={host_campus_options}
              disabled={false}
              initialValue={search_params.host_campus}
              onChange={onSelectChange}
            />

          <div className="form-row">
            <InstructorSearch
              instructors={instructors}
              instructor_name={search_params.instructor_name}
            />
            <Select
              className="cce-search-form-select"
              iconClassName="cce-advanced-search-form-control-icon-container"
              label="Courses with Preview Video:"
              name="with_preview"
              options={course_with_preview_video_options}
              disabled={false}
              initialValue={search_params.with_preview}
              onChange={onSelectChange}
            />
          </div>

          <div className="cce-search-form-input-container">
            <fieldset>
                <legend>Cross-Campus Approval:</legend>
                <div className="cce-search-form-toggle">
                  <label>
                    <input type="checkbox" name="approvals" value="ge_approval" defaultChecked={search_params.approvals && search_params.approvals.includes('ge_approval')}/>
                    <span>General Education</span>
                  </label>
                </div>
                <div className="cce-search-form-toggle">
                  <label>
                    <input type="checkbox" name="approvals" value="pre_major_approval" defaultChecked={search_params.approvals && search_params.approvals.includes('pre_major_approval')}/>
                    <span>Major Preparation</span>
                  </label>
                </div>
                <div className="cce-search-form-toggle">
                  <label>
                    <input type="checkbox" name="approvals" value="major_approval" defaultChecked={search_params.approvals && search_params.approvals.includes('major_approval')}/>
                    <span>Major Requirement</span>
                  </label>
                </div>
                <div className="cce-search-form-toggle">
                  <label>
                    <input type="checkbox" name="approvals" value="equivalent_to_approval" defaultChecked={search_params.approvals && search_params.approvals.includes('equivalent_to_approval')}/>
                    <span>Course Equivalence</span>
                  </label>
                </div>
            </fieldset>
            </div>

          <div className="form-row-block">
            <hr/>
            <p className="cce-advanced-search-form-schedule-text">If you're looking for courses that have scheduled meeting days and times, use day and time filters.</p>
          </div>

          <div className="cce-search-form-input-container">
            <fieldset>
              <legend>Course Day(s):</legend>
                <div className="cce-search-form-toggle">
                  <label>
                    <input type="checkbox" name="dow" value="Monday" defaultChecked={search_params.dow && search_params.dow.includes('Monday')}/>
                    <span>Monday</span>
                  </label>
              </div>
              <div className="cce-search-form-toggle">
                <label>
                  <input type="checkbox" name="dow" value="Tuesday" defaultChecked={search_params.dow && search_params.dow.includes('Tuesday')}/>
                  <span>Tuesday</span>
                </label>
              </div>
              <div className="cce-search-form-toggle">
                <label>
                  <input type="checkbox" name="dow" value="Wednesday" defaultChecked={search_params.dow && search_params.dow.includes('Wednesday')}/>
                  <span>Wednesday</span>
                </label>
              </div>
              <div className="cce-search-form-toggle">
                <label>
                  <input type="checkbox" name="dow" value="Thursday" defaultChecked={search_params.dow && search_params.dow.includes('Thursday')}/>
                  <span>Thursday</span>
                </label>
              </div>
              <div className="cce-search-form-toggle">
                <label>
                  <input type="checkbox" name="dow" value="Friday" defaultChecked={search_params.dow && search_params.dow.includes('Friday')}/>
                  <span>Friday</span>
                </label>
              </div>
            </fieldset>
          </div>

          <div className="form-row">
            <div className="modal-row">
              <Select
                className={makeClassName('cce-search-form-select', 'time-from')}
                iconClassName="cce-advanced-search-form-control-icon-container"
                label="Time Range:"
                name="start_time"
                options={time_range_options}
                disabled={false}
                initialValue={search_params.start_time}
                onChange={onSelectChange}
              />
              <span>to</span>
              <Select
                className={makeClassName('cce-search-form-select', 'time-to')}
                iconClassName="cce-advanced-search-form-control-icon-container"
                label="&nbsp;"
                name="end_time"
                options={time_range_options}
                disabled={false}
                initialValue={search_params.end_time}
                onChange={onSelectChange}
              />
            </div>
          </div>

          <div className="cce-search-form-row cce-advanced-search-submit-row">
            <Button className="cce-advanced-search-clear-button" type="submit">
              Search
            </Button>
            <Button className="cce-advanced-search-clear-button" onClick={onClear}>
              Clear
            </Button>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );

};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdvancedSearchModal);