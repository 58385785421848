import React, { Fragment } from "react";
import "./style.scss";
import dompurify from 'dompurify';
import parse from 'html-react-parser'


const FacultyInformation = props => {
    const { course_creators } = props;

    return <Fragment>
      <div id='faculty_info'>
        <div className='course_creators'>
          <h4>COURSE CREATORS</h4>
          {course_creators.map((creator, index) => (
            <Fragment key={creator.person_id}>
              <div className='course_creator_info'>
                <div className='faculty_photo'>
                  <img className="ie_image_cover" alt="" src={creator.photo}/>
                </div>
                <div className='ie_image_cover_neighbor'>
                  <h4>{creator.first_name} {creator.last_name}</h4>
                  <div className="course_creator_bio mobile_small_text">
                    <span className="visible_print">
                      <span>
                        <span>{parse(dompurify.sanitize(creator.bio, {ALLOWED_TAGS: []}))}</span>
                      </span>
                    </span>
                    <span className="visible_screen mobile_small_text" id="no-style">
                      <span>
                        <span>{parse(dompurify.sanitize(creator.bio, {ALLOWED_TAGS: []}))}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </Fragment>
            ))
          }

        </div>
      </div>
    </Fragment>
};

export default FacultyInformation;