import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';

import parse from 'html-react-parser';

import {
  setShowAlertMessage,
} from "../../actions"

import "./style.scss";

const mapStateToProps = (state, props) => ({
  alertMessage: state.filters.data.alert_message,
  showAlert: state.filters.showAlert
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    onClose: () => dispatch(setShowAlertMessage(false))
  }
}

class Alert extends Component {
  render () {
    const { alertMessage, showAlert, onClose } = this.props;
    return (

      <Fragment>
        {
          showAlert && alertMessage && alertMessage.length > 0 && (
            <div className="alert-container">
              {parse(alertMessage)}
              <button className="close" onClick={onClose}>
              </button>
            </div>
          )
        }

      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Alert);