import React from "react";
import { connect } from 'react-redux';

import "./style.scss";

import { makeClassName } from "../../utils";
import { ErrorList, SuccessBanner } from '../widgets';

export const PageContainer = props => {
  const { className } = props;
  return (
    <div className="app-content">
      <div className={makeClassName("main-bg", className)}>
        {props.children}
      </div>
    </div>
  );
};

const PageContentComponent = props => {
  const { inSearchModal } = props;
  return (
    <div aria-live="polite" className={makeClassName('main-container', inSearchModal && 'search-modal-open')}>
      {props.children}
    </div>
  );
};

export const PageContent = connect(
  state => ({
    errors: state.messages.errors,
    successMessage: state.messages.successMessage,
    inModal: state.messages.inModal,
    inSearchModal: state.search.show_advanced_search
  })
)(PageContentComponent);
