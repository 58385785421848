import React, { Component } from "react";
import { Route,  BrowserRouter as Router, Routes } from "react-router-dom";
import history from "./history";

import './App.css';

import {
  SEARCH_ROUTE,
  SEARCH_NONUC_ROUTE,
  SEARCH_RESULTS_ROUTE,
  SEARCH_COURSE_OFFERING_DETAILS_ROUTE,
  CATALOG_ROUTE,
  CATALOG_COURSE_DETAILS_ROUTE,
  SUMMER_COURSES_ROUTE
} from "./constants/routes";

import { Search, CourseOfferingDetails, Catalog, CatalogCourseDetails, SummerCourses } from './pages';

class App extends Component{
  renderApp(){
    return (
      <Router history={history}>
        <Routes>
          <Route path={SEARCH_ROUTE} element={<Search/>} />
          <Route path={SEARCH_RESULTS_ROUTE} element={<Search/>} />
          <Route path={SEARCH_NONUC_ROUTE} element={<Search/>} />
          <Route path={SEARCH_COURSE_OFFERING_DETAILS_ROUTE} element={<CourseOfferingDetails/>} />
          <Route path={CATALOG_ROUTE} element={<Catalog/>} />
          <Route path={CATALOG_COURSE_DETAILS_ROUTE} element={<CatalogCourseDetails/>} />
          <Route path={SUMMER_COURSES_ROUTE} element={<SummerCourses/>} />
        </Routes>
      </Router>

    );
  }

  render() {
    return this.renderApp();
  }
}

export default App;
