import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import { makeClassName } from '../../../utils';

class TextInput extends Component {
  state = {
    value: this.props.initialValue
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({
        value: this.props.initialValue
      })
    }
  }

  onChange = (event) => {
    console.log('onChange')
    if (this.props.onChange) {
      this.props.onChange(event);
    }

    this.setState({
      value: event.target.value
    });
  }

  render() {
    const { focusRef, pattern, name, id, className, label, type, placeholder, disabled, required } = this.props;

    return (
      <label className={makeClassName("ucop-text-input", className)}>
        {label}
        <input
          ref={focusRef}
          name={name}
          id={id}
          type={type}
          value={this.state.value}
          onChange={this.onChange}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          pattern={pattern}
        />
      </label>

    );
  }
}

TextInput.defaultProps = {
  type: 'text',
  initialValue: ''
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.node,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  focusRef: PropTypes.object,
  pattern: PropTypes.string
};

export default TextInput;
