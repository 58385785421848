import {
  LOAD_COURSE_OFFERING_DETAILS,
  SET_LOAD_COURSE_OFFERING_DETAILS_STATUS,
  SET_SHOW_ALL_SECTIONS
} from '../constants/action-types';

const initialState = {
  course_offering_details: {
  },
  loaded: false,
  isLoading: false,
  showAllSections: false,
};

export default function courseOfferingDetails(state = initialState, action) {
  switch (action.type) {

    case LOAD_COURSE_OFFERING_DETAILS:
      return {
        ...state,
        course_offering_details: action.course_offering_details,
        loaded: true,
      };

    case SET_LOAD_COURSE_OFFERING_DETAILS_STATUS:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case SET_SHOW_ALL_SECTIONS:
      return {
        ...state,
        showAllSections: action.showAllSections
      };

    default:
      return state;
  }
}