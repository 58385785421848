import { createHashHistory } from 'history';
import { setPage } from './analytics';


const history =  createHashHistory({
    basename: '',
    hashType: 'slash'
});

history.listen(location => {
  setPage(location.pathname)
})

export default history;
