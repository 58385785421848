/**
 * @module constants/routes
 * @desc exported const data representing the front end routes
 */
export const ROOT_ROUTE = "/";

// SEARCH ROUTES
export const SEARCH_ROUTE = "/search";
export const SEARCH_RESULTS_ROUTE = "/search/results";
export const SEARCH_NONUC_ROUTE = "/search/nonuc";
export const SEARCH_COURSE_OFFERING_DETAILS_ROUTE = "/search/view";
export const CATALOG_ROUTE = "/search/catalog";
export const CATALOG_COURSE_DETAILS_ROUTE = "/search/catalog/view";
export const SUMMER_COURSES_ROUTE = "/summer-online-courses";