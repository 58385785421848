import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeClassName } from '../../utils';


import {
  PageContainer,
  PageContent,
} from '../../components';

import {
  Select,
  Table,
  Form,
} from '../../components/widgets';


import {
  fetchSummerCourses
} from "../../actions"

import './style.scss';

const mapStateToProps = (state, props) => ({
  campuses: state.summer.campuses,
  courses: state.summer.courses,
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadSummerCourses: searchParams => dispatch(fetchSummerCourses()),
  };
};

const CourseTitleLink = props => {
  const { course } = props;

  return (
    <Fragment>
      <a href={course.info_url} title="Opens in a New Window" target = "_blank" rel = "noopener noreferrer">{course.course_title}</a>
    </Fragment>
  );
};

const AdditionalCourseCreditLink = props => {
  const { course } = props;

  return (
    course.additional_credit_url ?
      <a href={course.additional_credit_url} title="Opens in a New Window" target = "_blank" rel = "noopener noreferrer">More info <span className="offscreen">about course credit for {course.course_title}</span></a> :
      ''
  );
};

const HostCampus = props => {
  const { course, campuses } = props;
  return (
    <span>{campuses.find(campus => campus.campus_alias === course.campus_alias).campus_name}
    </span>
  );
};

const ConnectedHostCampus = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HostCampus);

const HostCampusCredit = props => {
  const { course } = props;
  return (
    <span>{course.course_credit_value + ' ' + course.course_credit_term + ' units'}</span>
  )
}

const HowToEnrollLink = props => {
  const { course } = props;

  return (
    course.enroll_url ?
      <a href={course.enroll_url} title="Opens in a New Window" target = "_blank" rel = "noopener noreferrer">Learn more <span className="offscreen">about enrolling in {course.course_title}</span></a> :
      ''
  );
};

const SUMMER_COURSES_COLUMNS = [
  {
    name: 'course_code',
    displayName: 'Course Code',
    width: 80,
    displayTransformer: course => course.course_code,
    sort: (a, b) => {
      if (a.course_code > b.course_code) return 1;
      if (a.course_code < b.course_code) return -1;
      return 0;
    },
  },

  {
    name: 'course_title',
    displayName: 'Course Title',
    width: 240,
    displayTransformer: (course, searchParams) => <CourseTitleLink course={course} searchParams={searchParams}/>,
    sort: (a, b) => {
      if (a.course_title > b.course_title) return 1;
      if (a.course_title < b.course_title) return -1;
      if (a.course_code > b.course_code) return 1;
      if (a.course_code < b.course_code) return -1;
      return 0;
    },
    defaultSort: true
  },

  {
    name: 'campus_alias',
    displayName: 'Host Campus',
    width: 70,
    displayTransformer: course => <ConnectedHostCampus course={course} />,
    sort: (a, b) => {
      if (a.campus_alias > b.campus_alias) return 1;
      if (a.campus_alias < b.campus_alias) return -1;
      if (a.course_code > b.course_code) return 1;
      if (a.course_code < b.course_code) return -1;
      return 0;
    },
  },

  {
    name: 'course_credit_value',
    displayName: 'Host Campus Credit',
    width: 80,
    displayTransformer: course => <HostCampusCredit course={course} />,
  },

  {
    name: 'additonal_credit_title',
    displayName: 'Additional Course Credit',
    width: 110,
    displayTransformer: course => <AdditionalCourseCreditLink course={course} />,
  },

  {
    name: 'how_to_enroll',
    displayName: 'How To Enroll',
    width: 80,
    displayTransformer: course => <HowToEnrollLink course={course} />,
  },
];

const SummerCourses= (props) => {
  const { courses, campuses, loadSummerCourses } = props;
  const [filterCampus, setFilterCampus] = useState('');
  const [filterAdditionalCourse, setFilterAdditionalCourse] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(100);


  useEffect(() => {
    loadSummerCourses();
  }, []);

  const onCampusChange = (e) => {
    setFilterCampus(e.target.value);
  }

  const onAdditionalCourseDetailChange = (e) => {
    setFilterAdditionalCourse(e.target.checked);
  }

  const itemsPerPageOptions =
  [
    {'value' : '50', 'name': '50', 'default': false},
    {'value' : '100', 'name': '100', 'default': true},
    {'value' : '2000', 'name': 'All', 'default': false}
  ];

  let filteredCourses = courses;
  if (filterCampus) {
    filteredCourses = courses.filter(item => item.campus_alias === filterCampus)
  }
  if (filterAdditionalCourse) {
    filteredCourses = filteredCourses.filter(item => item.additional_credit_url !== '')
  }

  const campuses_options = [{'name': 'All campuses', 'value': ''}].concat(campuses.map(campus => ({name: campus.campus_name, value: campus.campus_alias})));

  return (
    <PageContainer className="cce-summer-courses">
      <PageContent>
        <div className="cce-container" id="page">
        <div className="clear"></div>
          <Form className='cce-summer-form'>
            <Select
              className={makeClassName('cce-summer-form-select')}
              iconClassName="cce-summer-form-select-icon"
              label="Filter by campus"
              name="host_campus"
              options={campuses_options}
              disabled={false}
              initialValue={''}
              onChange={onCampusChange}
              id='filter_by_campus'
            />
            <div>
              <label className='summer-checkbox'>
                <input type="checkbox" name="additional_course_credit_detail" value="Additional course credit detail" onChange={onAdditionalCourseDetailChange}/>
                <span>Show courses with additional course credit detail</span>
              </label>
            </div>
          </Form>
        {
          <Fragment>
            {
              <Fragment>
                {
                  <Table
                    className="summer-table"
                    caption=""
                    columns={SUMMER_COURSES_COLUMNS}
                    data={filteredCourses}
                    itemsPerPage={itemsPerPage}
                    itemsPerPageOptions={itemsPerPageOptions}
                    page={1}
                    searchParams={[]}
                    hideViewDetailsButton={true}
                  />
                }
              </Fragment>
            }
          </Fragment>
        }
      </div>
      </PageContent>
    </PageContainer>
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SummerCourses);