/**
 * @module utils
 * @desc Helper methods and classes
 */

///////////////////////////////////////////////////////////////
// ENV CHECKS /////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
/**
 * Returns the name of the environment
 * @return {string} env name
 */
export function getEnv() {
  return process.env.NODE_ENV;
}

/**
 /* Checks if we are in the production environment
* @return {boolean} true if production
*/
export function isProd() {
  return getEnv() === "production";
}

/**
 /* Checks if we are in the development environment
* @return {boolean} true if development
*/
export function isDev() {
  return getEnv() === "development";
}

/**
 /* Checks if we are in the test environment
* @return {boolean} true if test
*/
export function isTest() {
  return getEnv() === "test";
}

///////////////////////////////////////////////////////////////
// HELPER METHODS /////////////////////////////////////////////
///////////////////////////////////////////////////////////////
/**
 * Combines strings into a single string to use with a react className prop
 * @param {...string} names - A variable amount of names to combine
 * @return {string}
 * @example
 *  makeClassName('ucop-form', 'login-form', 'hidden')
 */
export function makeClassName(...names) {
  return names
    .map(name => (name ? name.trim() : null))
    .filter(name => name)
    .join(" ");
}

/**
 * @param {string} string
 * @param {boolean} includeWhiteSpace - if true then ' ' would not be considered empty
 * @return {boolean}
 */
export function isEmptyString(string = "", includeWhiteSpace = false) {
  string = includeWhiteSpace ? string : string.trim();

  return !string;
}
