import React from 'react';
import './style.scss';
import { makeClassName } from '../../../utils';

const Spinner = props => {
  const { className, light, small } = props;
  return (
    <div className={makeClassName("ucop-spinner", className)}>
      <div className={makeClassName("ucop-spinner-contents", light && "light", small && "small")}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="sr_hide" autoFocus>
        Loading
      </div>
    </div>
  );
};

export default Spinner;
