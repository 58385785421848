import {
  LOAD_SUMMER_COURSES
} from '../constants/action-types';

const initialState = {
  courses: [],
  campuses: [],
  loaded: false,
  isLoading: true,
};

export default function summer(state = initialState, action) {
  switch (action.type) {
    case LOAD_SUMMER_COURSES:
      return {
        ...state,
        courses: action.summer.courses,
        campuses: action.summer.campuses,
        loaded: true,
      };
    default:
      return state;
  }
}