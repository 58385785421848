import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./style.scss";

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: this.props.page || 1
    };
  }

  increment = (amount) => {
    this.setState({
      currentPage: this.state.currentPage + amount
    });
    if (typeof this.props.onChange === "function") {
      this.props.onChange(this.state.currentPage + amount, this.props.totalPages);
    }
  };

  decrement = (amount) => {
    this.setState({
      currentPage: this.state.currentPage - amount
    });
    if (typeof this.props.onChange === "function") {
      this.props.onChange(this.state.currentPage - amount, this.props.totalPages);
    }
  };

  componentDidUpdate(prevProps) {
    // if we get a new page number from the table then we just go there right away
    if (
      (this.props.page !== this.state.currentPage) &&
      (this.props.page !== prevProps.page)
    ) {
      this.setState({
        currentPage: this.props.page
      })
    }
  }

  render() {
    const { focusRef } = this.props;
    const onFirstPage = this.state.currentPage === 1;
    const onLastPage = this.state.currentPage === this.props.totalPages;
    const currentpage = this.state.currentPage;
    const availablePreviousPages = currentpage - 1 > 5 ? Math.max(5, Math.min(9, currentpage - 1)) : currentpage - 1;
    const availableNextPages = this.props.totalPages - currentpage > 5 ? Math.max(5, Math.min(9, this.props.totalPages - currentpage)) : this.props.totalPages - currentpage;
    return (
      <div className="pagination">
        {!onFirstPage && (
          <Fragment>
            <div className="left">
              <button onClick={() => this.decrement(1)} aria-label="previous page">
                &lsaquo; Previous
              </button>
            </div>
          </Fragment>
         )}
        <div className="right">
        <span>Go To Page:</span>
        {!onFirstPage && (
          <Fragment>
            <button onClick={() => this.decrement(currentpage - 1)} aria-label="first page">
              &laquo; First
            </button>
            {(() => {
              let buttons = [];
              for (let i = currentpage - availablePreviousPages; i < currentpage; i++) {
                buttons.push(
                  <button
                    key={i}
                    onClick={() => this.decrement(currentpage - i)}
                    aria-label={`Page ${i}`}
                  >
                    {i}
                  </button>
                );
              }
              return buttons;
            })()}
          </Fragment>
        )}
        <button ref={focusRef} className="active" aria-label={`Active page ${currentpage}`}>
          {currentpage}
        </button>
        {!onLastPage && (
          <Fragment>
            {(() => {
              let buttons = [];
              for (let i = currentpage + 1; i <= currentpage + availableNextPages; i++) {
                buttons.push(
                  <button
                    key={i}
                    onClick={() => this.increment(i - currentpage)}
                    aria-label={`Page ${i}`}
                  >
                    {i}
                  </button>
                );
              }
              return buttons;
            })()}
            <button onClick={() => this.increment(this.props.totalPages - currentpage)} aria-label="last page">
              Last &raquo;
            </button>
            <button onClick={() => this.increment(1)} aria-label="next page">
              Next &rsaquo;
            </button>
          </Fragment>
        )}
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  onChange: PropTypes.func,
  page: PropTypes.number,
  totalPages: PropTypes.number.isRequired,
  focusRef: PropTypes.object
};

export default Pagination;
