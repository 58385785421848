import React, { Fragment, useState } from "react";
import "./style.scss";
import dompurify from 'dompurify';
import parse from 'html-react-parser'

const CourseApproval = props => {
  const {course_approval} = props;
  return <Fragment>
  {Array.isArray(course_approval) ?
    course_approval.map((approval, index) => (
      <Fragment key={index}>
      <span className="course_approval_content mobile_small_text">{parse(dompurify.sanitize(approval, {ALLOWED_TAGS: []}))}</span><br/>
      </Fragment>
    )) :
    Object.keys(course_approval).toReversed().map((approval, index) => (
      <Fragment key={index}>
        {!isNaN(+approval) ?
          <span className="course_approval_content mobile_small_text">{parse(dompurify.sanitize(course_approval[approval], {ALLOWED_TAGS: []}))}</span>
          : <span className="course_approval_content mobile_small_text">{approval}: {parse(dompurify.sanitize(course_approval[approval], {ALLOWED_TAGS: []}))}</span>
        }
        <br/>
      </Fragment>
    ))
  }
  </Fragment>
}

const CourseApprovals = props => {
  const {course_approvals} = props;
  return <Fragment>
    <div id="other_approvals">
      {Object.keys(course_approvals).map((approval, index) => (
        index >= 0 ? <Fragment key={index}>
          <h4 className="course-approval-campus">{parse(dompurify.sanitize(approval, {ALLOWED_TAGS: []}))}:</h4>
          <CourseApproval course_approval={course_approvals[approval]} key={index}/>
        </Fragment> : null
        ))
      }
    </div>
  </Fragment>
}

const EnrollmentInformation = props => {
    const { catalog_course_details } = props;
    const course = catalog_course_details.course;
    const course_approvals = catalog_course_details.course_approvals;
    return <Fragment>
      <div id="enrollment_info" className="mobile_small_text">
        <div className="x-campus_info x-campus_info_catalog mobile_small_text">
          <div>
            <h4 className="course_header">COURSE CREDIT<br/></h4>
            <div className="course_credit_description">
              <p className="mobile_small_text">
                {parse(dompurify.sanitize(course.credit_description))}
              </p>
              </div>
            <CourseApprovals course_approvals={course_approvals}/>
          </div>
        </div>
        {course.prerequisites ?
          <Fragment>
            <h4>Prerequisites</h4>
            <p>
              {course.prerequisites}
            </p>
          </Fragment> : null
        }

        {course.course_fee ?
          <Fragment>
            <h4>Course Fees</h4>
            <p className="mobile_small_text">{course.course_fee}</p>
          </Fragment>
          : null
        }

        {course.additional_info ?
          <Fragment>
            <h4>More About The Course</h4>
            <p className="mobile_small_text">
              {parse(dompurify.sanitize(course.additional_info))}
            </p>
          </Fragment> : null
        }
        {course.course_url ?
          <p className="mobile_small_text">
            <a href={course.course_url} target="_blank">Relevant Website</a>
          </p> : null
        }
      </div>
    </Fragment>
};

export default EnrollmentInformation;