import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { makeClassName } from '../../../utils';

class Button extends Component {
  render() {
    const { name, label, id, className, type, onClick, disabled, children, focusRef } = this.props;
    return (
      <button
        ref={focusRef}
        name={name}
        id={id}
        className={makeClassName("ucop-button", className)}
        type={type}
        onClick={onClick}
        disabled={disabled}
        aria-label={label}
      >
        {children}
      </button>
    );
  }
}

Button.defaultProps ={
  type: 'button'
};

Button.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  focusRef: PropTypes.object,
  disabled: PropTypes.bool
};

export default Button;
