/**
 * @module selectors/filters
 * @desc Selectors for search data
 */

import { createSelector } from 'reselect';

const getSearchFilters = state => state.filters.data;

export const getCampusFilterList = createSelector(
  [getSearchFilters],
  filters => filters.campus_filter.map(campus => ({name: campus.campus_name, value: campus.campus_id}))
);

export const getTermFilterList = createSelector(
  [getSearchFilters],
  filters => filters.term_filter.map(term => ({name: term.term_name, value: term.term_group_id}))
);

export const getSubjectAreaFilterList = createSelector(
  [getSearchFilters],
  filters => filters.subject_area_filter.map(subject_area => ({name: subject_area.subject_area_name, value: subject_area.subject_area_id}))
);

export const getHoursFilterList = createSelector(
  [getSearchFilters],
  filters => filters.hours_filter.map(hours => ({name: hours.hours_name, value: hours.hours_value}))
);