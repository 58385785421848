import React, { Component, Fragment, Link } from "react";
import "./style.scss";
import dompurify from 'dompurify';
import parse from 'html-react-parser'

class KeyInformation extends Component {
  onShowNotifyMe = () => {
    this.props.onShowNotifyMe();
  }

  render() {
    const { catalog_course_details} = this.props;
    const course = catalog_course_details.course;
    const openNewWindow = (url) => {
      window.open(url, 'mywin', 'left=20,top=20,width=1000,height=500,toolbar=1,resizable=0')
    }

    return <Fragment>
      <div id='catalog_key_information'>
        <ul className='social_links_list'>
          <li className='print'>
            <button onClick={window.print}><img src={process.env.REACT_APP_CDN_URL + "/images/icons/search/print.png"} alt="Print" /></button>
          </li>
          <li className='fb-m'>
            <button onClick={() => openNewWindow("https://www.facebook.com/sharer/sharer.php?u=" + process.env.REACT_APP_PUBLIC_URL + "/catalog/view?course_id=" + course.course_id)}><img src="https://uconline.edu/sites/default/files/facebook.png" alt="Share the course on Facebook" /></button>
          </li>
          <li className='tw-m'>
            <button onClick={() => openNewWindow("http://twitter.com/share?text=" + process.env.REACT_APP_PUBLIC_URL + "/view?offering_id=" + "/catalog/view?course_id=" + course.course_id)}><img src="https://uconline.edu/sites/default/files/twitter.png" alt="Share the course on Twitter" /></button>
          </li>
          <li className='li-m'>
            <button onClick={() => openNewWindow("https://www.linkedin.com/cws/share?url=" + process.env.REACT_APP_PUBLIC_URL + "/view?offering_id=" + "/catalog/view?course_id=" + course.course_id)}><img src="https://uconline.edu/sites/default/files/linkedin.png" alt="Share the course on Linkedin" /></button>
          </li>
          <li>
            <span className="share_text mobile_small_text">Share</span>
          </li>
        </ul>
        <div className="academic_calendar_container mobile_small_text"><a className="academic_calendar pdf_icon" href={process.env.REACT_APP_CDN_URL + "/docs/academic-calendar.pdf"} target="_blank">Academic Calendar<span className="offscreen">(Opens a PDF in a new tab)</span></a></div>
        <div className="info_container">
            <strong className="mobile_small_text">Credit: {catalog_course_details.unit_equivalency.quarter_equivalency_hours + " quarter units / " + catalog_course_details.unit_equivalency.semester_equivalency_hours + " semester units credit"} <br/>
            {catalog_course_details.host_campus.campus_name}, {catalog_course_details.course.course_department} <br/>
            </strong>
        </div>
        <div className="catalog_info_button">
          {course.syllabus ?
            <a className="course_info_button mobile_small_text" cc-data={course.course_code} href={course.syllabus} target="_blank" rel="noopener noreferrer">Syllabus<span className="offscreen">(Opens a PDF in a new tab)</span></a>
            : null
          }
          <a className="course_info_button notify_me" id="notify_me" href="#section_anchor" onClick={this.onShowNotifyMe}>Notify me when available</a>
        </div>
        <div>
          <p className='long_description mobile_small_text'>{parse(dompurify.sanitize(course.long_description))}</p>
        </div>
      </div>
    </Fragment>
  };
};

export default KeyInformation;