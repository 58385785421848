import {
  LOAD_CATALOG_COURSE_DETAILS,
  SET_LOAD_CATALOG_COURSE_DETAILS_STATUS,
} from '../constants/action-types';

const initialState = {
  catalog_course_details: {
  },
  loaded: false,
  isLoading: false,
  showAllSections: false,
};

export default function catalogCourseDetails(state = initialState, action) {
  switch (action.type) {

    case LOAD_CATALOG_COURSE_DETAILS:
      return {
        ...state,
        catalog_course_details: action.catalog_course_details,
        loaded: true,
      };

    case SET_LOAD_CATALOG_COURSE_DETAILS_STATUS:
      return {
        ...state,
        isLoading: action.isLoading
      };
    default:
      return state;
  }
}