/**
 * @module actions/messages-actions
 * @desc redux actions related to setting error and success messages. These will mostly be dispatched by other actions
 */

import {
  SET_ERRORS,
  PUSH_ERRORS,
  SET_SUCCESS_MESSAGE,
  SET_MODAL_MESSAGE_STATUS
} from '../constants/action-types';

/**
 * Redux action to set the global error text
 * @function
 * @param {Array.<node>|node} errors a single react node (jsx or string) or array of nodes
 */
export const setErrors = errors => {
  //errors = Array.isArray(errors) ? errors : [errors];
  return {
    type: SET_ERRORS,
    errors
  }
};

/**
 * Redux action to append to the global error text
 * @function
 * @param {Array.<node>|node} errors a single react node (jsx or string) or array of nodes
 */
export const pushErrors = errors => {
  //errors = Array.isArray(errors) ? errors : [errors];
  return {
    type: PUSH_ERRORS,
    errors
  }
};

/**
 * Redux action to clear the global error text
 * @function
 */
export const resetErrors = () => ({
  type: SET_ERRORS,
  errors: {}
});

/**
 * Redux action set the global success text
 * @function
 * @param {node} successMessage a react node (jsx or string)
 */
export const setSuccessMessage = successMessage => ({
  type: SET_SUCCESS_MESSAGE,
  successMessage
});

/**
 * Redux action to clear the global success text
 * @function
 */
export const resetSuccessMessage = () => ({
  type: SET_SUCCESS_MESSAGE,
  successMessage: null
});

/**
 * Redux action to indicate whether we are in a modal context. This should not really be needed outside of the modal component.
 * @function
 * @param {boolean} inModal
 */
export const setModalMessageStatus = inModal => ({
  type: SET_MODAL_MESSAGE_STATUS,
  inModal
});

/**
 * Thunk action to clear both the global error and global success text
 * @function
 */
export const resetAllMessages = () => dispatch => {
  dispatch(resetErrors());
  dispatch(resetSuccessMessage());
};
