
import React, { Fragment, useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import {
  fetchNotifyMe,
  setShowNotifyMe,
  setNotifyMeParams
} from "../../../actions"

import { Button, Form } from "../../../components/widgets";

import { SF_TERM_FIELD_ID, SF_COURSE_FIELD_ID } from "../../../constants";

const mapStateToProps = (state, props) => ({
  errors: state.messages.errors,
  successMessage: state.messages.successMessage,
  params: state.notifyMe.data,
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchNotifyMe: (data) => dispatch(fetchNotifyMe(data)),
    setNotifyMeParams: (params) => dispatch(setNotifyMeParams(params)),
    onCloseNotifyMe: () => dispatch(setShowNotifyMe(false)),
  };
};

const NotifyMeModal = (props) => {
  const [ notifyError, setNotifyError ] = useState(false);
  const [ emailError, setEmailError ] = useState(false);

  const focusRef = useRef(null);
  useEffect(() => {
    focusRef.current?.focus();
  }, []);

  const onClear = () => {
    props.setNotifyMeParams({
      first_name: '',
      last_name: '',
      email: '',
      term: [],
    })
  }

  const onChange = (e) => {
    const { params, setNotifyMeParams } = props;
    setNotifyMeParams({
      ...params,
      [e.target.name]: e.target.value
    })
  }

  const onCheck = (e) => {
    const { params, setNotifyMeParams } = props;
    if (e.target.checked) {
      setNotifyMeParams({
        ...params,
        [e.target.name]: [...params[e.target.name], e.target.value]
      })
    } else {
      setNotifyMeParams({
        ...params,
        [e.target.name]: params[e.target.name].filter(item => item !== e.target.value)
      })
    }

  }

  const onSubmit = () => {
    const { params, fetchNotifyMe, course, returnURL, errors } = props;
    setNotifyError(false);
    setEmailError(false);
    if (params.first_name.length === 0 ||
        params.last_name.length === 0 ||
        params.email.length === 0) {
      setNotifyError(true);
      return;
    }

    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if(!regex.test(params.email)) {
      setEmailError(true);
      return;
    }

    const updated_params = {
      ...params,
      [SF_TERM_FIELD_ID]: params.term.join(","),
      [SF_COURSE_FIELD_ID]: course.course_id,
      "lead_source": "Course Catalog - Search",
    }
    setNotifyMeParams({
      updated_params
    })
    fetchNotifyMe({...updated_params, term: params.term.join(",")});
    if (Object.keys(errors).length > 0) {
      window.location.href = returnURL + "&notify_success=0";
    }
    window.location.href = returnURL + "&notify_success=1";
  }

  const { course, onCloseNotifyMe, params, terms } = props;
  return (
    <>
    <div className="cce-notify-me-modal-backdrop"></div>
    <div className="cce-notify-me-modal-container">
      <div className="cce-notify-me-modal-content">
      <div className="cce-notify-me-modal-header-row">
        <span className="cce-notify-me-modal-title">Please Provide your Contact Information</span>
        <button className="cce-notify-me-modal-close-button" onClick={onCloseNotifyMe}><span className="sr_hide">close notify me</span></button>
      </div>
      {notifyError && <div className="error" id="notify_error">* To be notified, please provide all requested information</div>}
      {emailError && <div className="error" id="email_error">* Please enter valid email.</div>}
      <Form name="notify_form" id="notify_form" method="POST" onSubmit={onSubmit}>
        <div>
          <p>
            We'll notify you when <strong>{course.course_title}, {course.course_code}</strong> becomes available
          </p>
        </div>
        <div className="form-row">
          <div className="form-block">
            <div className="input-label">First Name:*</div>
            <div className="cce-notify-me-form">
              <input name="first_name" id="first_name" aria-label="First Name" value={params.first_name} onChange={onChange} ref={focusRef}/>
            </div>
          </div>

          <div className="form-block">
            <div className="input-label">Last Name:*</div>
            <div className="cce-notify-me-form">
              <input name="last_name" id="last_name" aria-label="Last Name" value={params.last_name}  onChange={onChange}/>
            </div>
          </div>

          <div className="form-block">
            <div className="input-label">Email:*</div>
            <div className="cce-notify-me-form">
              <input name="email" id="email" aria-label="Email" value={params.email}  onChange={onChange}/>
            </div>
          </div>
        </div>

        <div className="form-block cce-notify-me-form-input-container ">
          <fieldset>
          <legend>Term(s) you're interested in:*</legend>
          {
            terms.map((term, index) => (
              <div className="cce-notify-me-form-toggle " key={index}>
              <label>
                <input type="checkbox" name="term" value={term} checked={params.term && params.term.includes(term)} onChange={onCheck}/>
                <span>{term}</span>
              </label>
            </div>
            ))
          }
          </fieldset>
        </div>

        <div className="cce-notify-me-submit-row">
          <Button className="cce-notify-me-button" id="submit_notify_form" type="submit" name="submit">
            Submit
          </Button>
          <Button className="cce-notify-me-button" id="clear_search" onClick={onClear}>
            Clear
          </Button>
        </div>
      </Form>
    </div>
    </div>
    </>

  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotifyMeModal);