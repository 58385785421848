import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { makeClassName } from '../../utils';

import {
  SEARCH_RESULTS_ROUTE,
  SEARCH_NONUC_ROUTE,
} from "../../constants/routes";

import {
  PageContainer,
  PageContent,
} from '../../components';

import {
  Select,
  Button,
  Form,
} from '../../components/widgets';

import {
  fetchAndLoadSearchFilters,
  fetchAndLoadSearchCourses,
  fetchAndLoadSearchInstructors,
  resetAllMessages,
  setErrors,
  setSelectedCourse,
  setShowAdvancedSearch,
  setSearchParamsValues,
  resetSearchData,
} from "../../actions"

import {
  getCampusFilterList,
  getTermFilterList,
  getSubjectAreaFilterList,
  getHoursFilterList
} from "../../selectors";

import CourseVideo from './course-video-modal';
import AdvancedSearch from './advanced-search-modal';

import { INTERNAL_ERROR } from "../../constants/errors"

import './style.scss';

const mapStateToProps = (state, props) => ({
  isLoading: state.filters.isLoading,
  campuses: getCampusFilterList(state),
  terms: getTermFilterList(state),
  subjects: getSubjectAreaFilterList(state),
  hours: getHoursFilterList(state),
  search_results: state.search.search_results,
  search_params: state.search.search_params,
  isSearching: state.search.isSearching,
  isLoaded: state.search.isLoaded,
  isNonUCStudent: state.search.isNonUCStudent,
  isSearchResults: state.search.isSearchResults,
  errors: state.messages.errors,
  selected_course: state.search.selected_course,
  show_advanced_search: state.search.show_advanced_search,
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    onSearch: search_params => dispatch(fetchAndLoadSearchCourses({ ...search_params })),
    resetAllMessages: () => dispatch(resetAllMessages),
    setErrors: () => dispatch(setErrors),
    fetchAndLoadSearchFilters: () => dispatch(fetchAndLoadSearchFilters()),
    onClose: () => dispatch(setSelectedCourse(null)),
    onCloseAdvancedSearch: () => dispatch(setShowAdvancedSearch(false)),
    onShowAdvancedSearch: () => dispatch(setShowAdvancedSearch(true)),
    setSearchParamsValues: search_params => dispatch(setSearchParamsValues(search_params)),
    resetSearchData: () => dispatch(resetSearchData()),
    fetchInstructors: () => dispatch(fetchAndLoadSearchInstructors()),
  };
};

class SearchLanding extends Component {

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp.bind(this), false);

    const {
      fetchAndLoadSearchFilters,
      fetchInstructors,
    } = this.props;

    fetchAndLoadSearchFilters();
    fetchInstructors();
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp, false);
  }

  onBackButton = (e) => {
    resetSearchData();
    window.history.back();
  }

  // Close modal dialogs on escape key
  handleKeyUp = (e) => {
    const { onCloseAdvancedSearch, onClose } = this.props;

    const keys = {
      27: () => {
        e.preventDefault();
        onCloseAdvancedSearch();
        onClose();
        window.removeEventListener('keyup', this.handleKeyUp, false);
      },
    };
    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
  }

  onSelectChange = (e) => {
    const { setSearchParamsValues, search_params, setErrors, errors } = this.props;
    let params = {...search_params, [e.target.name] : e.target.value, [e.target.name + "_display"] : e.target.options[e.target.selectedIndex].text};

    setSearchParamsValues(params);
    if (e.target.name === 'home_campus' && ('HOME_CAMPUS_SELECT_EMPTY_ERROR' in errors)) {
      delete errors['HOME_CAMPUS_SELECT_EMPTY_ERROR'];
      setErrors(errors);
    }

    if (e.target.name === 'term_year' && ('TERM_YEAR_SELECT_EMPTY_ERROR' in errors)) {
      delete errors['TERM_YEAR_SELECT_EMPTY_ERROR'];
      setErrors(errors);
    }
  }

  setPagination(pageNumber, itemsPerPage) {
    const { setSearchParamsValues, search_params } = this.props;
    let params = {...search_params, page_number : pageNumber, items_per_page: itemsPerPage};
    setSearchParamsValues(params);
    this.updateURL(params);
  }

  onSearch = (e) => {
    const { onSearch, search_params,  } = this.props;
    onSearch(search_params);
    if (search_params.home_campus.length > 0 && search_params.term_year.length > 0) {
      this.updateURL(search_params);
    }
  }

  updateURL(search_params) {
    const { navigate } = this.props;
    let url = search_params.home_campus === 'N/A' ? SEARCH_NONUC_ROUTE : SEARCH_RESULTS_ROUTE;
    url += "?"
    for (const [key, value] of Object.entries(search_params)) {
      if (Array.isArray(value)) {
        url += key + '=' + value.join(',') + '&';
      } else {
        url += key + '=' + value + '&';
      }
    }
    if (search_params.home_campus === 'N/A') {
      window.location.href = url;
    } else if (window.location.href.includes(SEARCH_RESULTS_ROUTE)) {
      navigate(url, { replace: true });
    } else {
      window.location.href = url;
    }
  }

  getSearchParamName(names, value) {
    for (let i = 0; i < names.length; i++) {
      if (names[i].value === value) {
        return names[i].name;
      }
    }
    return '';
  }

  getAdvancedSearchString(search_params, campuses, hours) {
    const cross_campus_approvals_mapping = {
      'ge_approval' : 'General Education',
      'pre_major_approval': 'Major Preparation',
      'major_approval' : 'Major Requirement',
      'equivalent_to_approval' : 'Course Equivalence'
    }

    let selected_criteria = [];
    if (search_params.host_campus.length > 0) {
      selected_criteria.push(this.getSearchParamName(campuses, parseInt(search_params.host_campus)));
    }

    if (search_params.dow && search_params.dow.length > 0) {
      selected_criteria.push(search_params.dow.join(', '));
    }

    const start_time = hours ? this.getSearchParamName(hours, search_params.start_time) : '';
    const end_time = hours? this.getSearchParamName(hours, search_params.end_time) : '';
    if (start_time.length > 0 && end_time.length > 0) {
      selected_criteria.push(start_time + ' - ' + end_time);
    } else if (start_time.length > 0) {
      selected_criteria.push('after ' + start_time);
    } else if (end_time.length > 0) {
      selected_criteria.push('before ' + end_time);
    }

    if (search_params.approvals) {
      search_params.approvals.forEach(item => selected_criteria.push((cross_campus_approvals_mapping[item])));
    }
    const searchedFor = selected_criteria.filter(Boolean).join(', ');
    return searchedFor.length > 0 ? "You searched for: " + searchedFor : "";
  }

  render() {
    const {
      isLoading,
      campuses,
      terms,
      subjects,
      isSearching,
      search_params,
      errors,
      show_advanced_search,
      onCloseAdvancedSearch,
      onShowAdvancedSearch,
    } = this.props;
    const home_campus_display = null;
    const initial_campuses_options = home_campus_display ? [{'name': home_campus_display, 'value': home_campus_display}] : [{'name': '- selection required -', 'value': ''}]
    const campuses_options = isLoading ? initial_campuses_options :
      [{'name': '- selection required -', 'value': ''}].concat(campuses).concat([{'name': 'Not a current UC student', 'value': 'N/A'}]);

    const term_year_display = null;
    const initial_terms_options = term_year_display ? [{'name': term_year_display, 'value': term_year_display}] : [{'name': '- selection required -', 'value': ''}]
    const terms_options = isLoading ? initial_terms_options: [{'name': '- selection required -', 'value': ''}].concat(terms);

    const subjects_options = isLoading ? [{'name': 'All subject areas', 'value': ''}] : [{'name': 'All subject areas', 'value': ''}].concat(subjects);
    const homeCampusError = ('HOME_CAMPUS_SELECT_EMPTY_ERROR' in errors) ? errors['HOME_CAMPUS_SELECT_EMPTY_ERROR'] : '';
    const termYearError = ('TERM_YEAR_SELECT_EMPTY_ERROR' in errors) ? errors['TERM_YEAR_SELECT_EMPTY_ERROR'] : '';
    const internalError = null // ('INTERNAL_ERROR' in errors) ? errors['INTERNAL_ERROR'] : null;
    return (
      <PageContainer className="search-results">
        <PageContent>
          <div className="cce-container" id="page">
					<div className="clear"></div>
              <div className={'index-search-form-container'}>
                {internalError &&
                    <div className='internal-error-container'>
                      <span role="alert" className="internal-error">{INTERNAL_ERROR}</span>
                    </div>}
                <Form className='cce-search-form' onSubmit={this.onSearch} key={search_params.home_campus + search_params.term_year + search_params.subject_area}>
                  <Select
                    className={makeClassName('cce-search-form-select', homeCampusError.length > 0 ? 'invalid' : '')}
                    iconClassName="cce-search-form-control-icon-container"
                    label="Select Your Campus (required):"
                    name="home_campus"
                    options={campuses_options}
                    disabled={false}
                    initialValue={search_params.home_campus}
                    onChange={this.onSelectChange}
                    errorMessage={homeCampusError}
                  />
                  <Select
                    className={makeClassName('cce-search-form-select', termYearError.length > 0 ? 'invalid' : '')}
                    iconClassName="cce-search-form-control-icon-container"
                    label="Select Term & Year (required):"
                    name="term_year"
                    options={terms_options}
                    disabled={false}
                    initialValue={search_params.term_year}
                    onChange={this.onSelectChange}
                    errorMessage={termYearError}
                  />
                  <Select
                    className="cce-search-form-select"
                    iconClassName="cce-search-form-control-icon-container"
                    label="Select Subject Area:"
                    name="subject_area"
                    options={subjects_options}
                    disabled={isSearching}
                    initialValue={search_params.subject_area}
                    onChange={this.onSelectChange}
                  />
                  <div className='cce-search-form-submit-container'>
                    <Button className={"cce-index-search-button"} type="submit">
                      Search
                    </Button>
                    <Button className="cce-advanced-search-button" onClick={onShowAdvancedSearch}>Advanced Search</Button>
                  </div>
                </Form>
              </div>
          { show_advanced_search && (<AdvancedSearch onClose={onCloseAdvancedSearch}/>) }
        </div>
        </PageContent>
      </PageContainer>
    );
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchLanding);