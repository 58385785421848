import { combineReducers } from 'redux';
import filters from './filters';
import search from './search';
import messages from './messages';
import courseOfferingDetails from './course-offering-details';
import catalogCourseDetails from './catalog-course-details';
import notifyMe from './notify-me';

export default combineReducers({
  filters,
  search,
  messages,
  courseOfferingDetails,
  catalogCourseDetails,
  notifyMe,
});
